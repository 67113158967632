/*
 * Colors
 */

$c-green: #88b145;
$c-red: #b80000;
$c-classyRed: #900;
$c-orange: #fb6b03;
$c-yellow: #fe0;
$c-lightBlue: #eceff6;

$c-white: #fff;
$c-offwhite: #f6f6f6;
$c-black: #000;
$c-grey: #777;
$c-darkGrey: #444;
$c-lightGrey: #ebebeb;
$c-lightishGrey: #e5e5e5;
$c-lightBlueGrey: #b3b7c8;
$c-darkBlueGrey: #646a7c;
$c-darkBlue: #232530;
$c-darkRedGrey: #2a2a2a;
$c-body: #212529;

$c-farFooter: #191a1f;
$c-nearBlack: #181818;


$c-lighten: rgba(255, 255, 255, 0.2);
$c-darken: rgba(0, 0, 0, 0.2);

$colors: (

		green: $c-green,
		red: $c-red,
		classyRed: $c-classyRed,
		orange: $c-orange,
		lightBlue: $c-lightBlue,
		white: $c-white,
		black: $c-black,
		grey: $c-grey,
		darkGrey: $c-darkGrey,
		lightGrey: $c-lightGrey,
		lightishGrey: $c-lightishGrey,
		lightBlueGrey: $c-lightBlueGrey,
		darkBlueGrey: $c-darkBlueGrey,
		darkRedGrey: $c-darkRedGrey,
		darkBlue: $c-darkBlue,
		nearBlack: $c-nearBlack,
		farFooter: $c-farFooter,
		lighten: $c-lighten,
		darken: $c-darken,

);


/*
 * Share colors
 */
$facebookBlue: #537bbd;
$twitterBlue: #78cdf0;
$rssOrange: #f39200;
$emailRed: #eb5755;

/*
 * Color utility classes
 */
@each $name, $color in $colors {
	.c-#{$name} {
		color: $color;
	}
	.c-#{$name}-light {
		color: lighten($color, 20%);
	}
	.c-#{$name}-lighter {
		color: lighten($color, 40%);
	}
	.c-#{$name}-dark {
		color: darken($color, 20%);
	}
	.c-#{$name}-darker {
		color: darken($color, 40%);
	}
	.bg-#{$name} {
		background-color: $color;
	}
	.bg-#{$name}-light {
		background-color: lighten($color, 20%);
	}
	.bg-#{$name}-lighter {
		background-color: lighten($color, 40%);
	}
	.bg-#{$name}-dark {
		background-color: darken($color, 20%);
	}
	.bg-#{$name}-darker {
		background-color: darken($color, 40%);
	}
}


/*
 * Opacity
 */

$tintOpacity: 0.2;
$overlayOpacity: 0.5;
$smokeOpacity: 0.7;


/*
 * Typography
 */

$defaultFontSize: 20;
$smallFontSize: 14;


/*
 * Spacing
 */

$navMargin: rems($smallFontSize);
$weePadding: $navMargin/3;
$defaultMargin: rems($defaultFontSize);
$wideMargin: rems($defaultFontSize*2);
$defaultPadding: rems($defaultFontSize);


/*
 * Timing
 */

$transitionTime: .5s;
$fastTransitionTime: .2s;


/*
 * Dimensions
 */

$borderRadius: 1px;

$tightFenceWidth: 768px;
$fenceWidth: 960px;
$wideFenceWidth: 1280px;
$standardWidth: 1280px;
$bodyWidth: 1920px;

// c.f. https://medium.freecodecamp.com/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
$breakpoints: (
	standard: (min: 0px, max: 999999px),
	fence: (min: 0px, max: $fenceWidth),
	phone: (min: 0px, max: 600px),
	tabletPortrait: (min: 601px, max: 900px), // "sm" grid
	tabletLandscape: (min: 901px, max: 1200px), // "md" grid
	desktop: (min: 1201px, max: 1800px), // "desktop" grid
	bigass: (min: 1801px, max: 999999px), // "lg" grid
);


/*
 * Flexbox Grid
 */

$grid-columns: 12;

$gutter-width: rems($defaultFontSize);
$gutter-width-half: $gutter-width * .5;
$gutter-width-double: $gutter-width * 2;

$outer-margin: $gutter-width-double * 2;

$gridBreakpoints: (
		sm: map-get(map-get($breakpoints, 'tabletPortrait'), 'min'),
		md: map-get(map-get($breakpoints, 'tabletLandscape'), 'min'),
		desktop: map-get(map-get($breakpoints, 'desktop'), 'min'),
		lg: map-get(map-get($breakpoints, 'bigass'), 'min'),
);

$flexboxgrid-max-width: rems($bodyWidth);





// Heading font sizes
$fs-hero: 48;
$fs-pageHeading: 40;
$fs-contentHeading: 36;
$fs-sectionHeading: 30;
$fs-cardHeading: 24;
$fs-sidebarHeading: 20;
$fs-textHeading: $defaultFontSize;
$fs-meta: $smallFontSize;
$fs-wee: $smallFontSize*0.9;

// Component font sizes
$fs-icon: 24;
$fs-toolbarIcon: 24;

// Font-weights
$fw-bold: 900;
$fw-normal: 400;
$fw-light: 300;


%serif, .serif {
	font-family: 'Sorts Mill Goudy', serif;
	font-weight: 400;
}

%sansSerif, .sansSerif {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
}