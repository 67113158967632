#tb-mega-searchform {
  background:$c-white;
  position:relative;
  z-index:1;
  border:1px solid $c-orange;
  height:auto;
  margin:0 auto;
}
#tb-mega-searchform .dropdown {
  height:38px;
  line-height:40px;
  color:$c-white;
  padding:0 15px;
  box-shadow:none;
  position:relative;
}
#tb-mega-searchform .dropdown i {
  color:$c-darkBlue;
  font-size:14px;
  position:absolute;
  right:35px;
  z-index:9;
  top:45%;
  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  -o-transform:translateY(-50%);
}
#tb-mega-searchform .dropdown .tb-title {
  margin-left:6px;
  font-size:18px;
}
#tb-mega-searchform .dropdown .caret {
  right:17px;
  position:absolute;
  top:50%;
  margin-top:-1px;
}
#tb-mega-searchform .dropdown select {
  position:absolute;
  left:0;
  top:0;
  line-height:1;
  border:none;
  color:$c-white;
  -moz-appearance:none;
  -webkit-appearance:none;
  font-size:14px;
  appearance:none;
  height:38px;
  cursor:pointer;
  @extend %sansSerif;
  font-weight:400;
  line-height:24px;
  padding:5px 20px;
  color:#232530;
  text-transform:capitalize;
  margin-bottom:0;
  width:160px;
}
#tb-mega-searchform .dropdown:after {
  content:'';
  width:1px;
  height:20px;
  background:rgba(37,37,37,0.1);
  position:absolute;
  top:50%;
  right:16px;
  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  -o-transform:translateY(-50%);
}
#tb-mega-searchform input { height:38px; }
#tb-mega-searchform input[type=text] {
  color:$c-lightBlueGrey;
  font-size:14px;
  padding:0 50px 0 14px;
  margin-bottom:0;
  width:100%;
  /* WebKit, Blink, Edge */
  /* Mozilla Firefox 4 to 18 */
  /* Mozilla Firefox 19+ */
  /* Internet Explorer 10-11 */
  /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
  @extend %sansSerif;
  font-weight:400;
  line-height:24px;
  border:none;
  background-color:$c-white;
}
#tb-mega-searchform select {
  background-color:$c-white;
}
#tb-mega-searchform input[type=text]::-webkit-input-placeholder { color:$c-lightBlueGrey; }
#tb-mega-searchform input[type=text]:-moz-placeholder {
  color:$c-lightBlueGrey;
  opacity:1;
}
#tb-mega-searchform input[type=text]::-moz-placeholder {
  color:$c-lightBlueGrey;
  opacity:1;
}
#tb-mega-searchform input[type=text]:-ms-input-placeholder { color:$c-lightBlueGrey; }
#tb-mega-searchform input[type=text]:placeholder-shown { color:$c-lightBlueGrey; }
#tb-mega-searchform input[type=text]:focus { color:$c-black; }
#tb-mega-searchform #searchsubmit, #tb-mega-searchform input[type=submit] {
  height:38px;
  position:absolute;
  right:0;
  top:0;
  width:107px;
  border:none;
  font-size:12px;
  font-weight:500;
  line-height:25px;
  background:$c-orange;
  color:$c-white;
  -webkit-transition:all 0.3s;
  -moz-transition:all 0.3s;
  -o-transition:all 0.3s;
  transition:all 0.3s;
}
#tb-mega-searchform #searchsubmit:hover, #tb-mega-searchform input[type=submit]:hover { background:$c-orange; }
#tb-mega-searchform [class*="col-xs"] { float:left; }
#tb-mega-searchform .col-xs-25 { width:175px; }
#tb-mega-searchform .col-xs-95 { width:calc( 100% - 175px ); }
#tb-mega-searchform .col-xs-99 { width:calc( 100% - 107px ); }

#global-search {
  border-color: #fb6b03;
}