#loginForm {
  font-size:14px;
  z-index:500;
  position: absolute;
  right: 15px;
  top: -21px;
  -webkit-transition:all 0.3s;
  -moz-transition:all 0.3s;
  -o-transition:all 0.3s;
  transition:all 0.3s;

  input[type="text"],
  input[type="password"] {
    width:220px;
    height:20px;
    font-size:14px;
    padding:16px 5px;
    border:1px solid $c-darkBlueGrey;
  }

  input[type="submit"] {
    width:100px;
    text-transform:uppercase;
    @extend %sansSerif;
    font-weight:500;
    font-size:13px;
    letter-spacing:1px;
    line-height:21px;
    margin-top: -1px;
    padding:7px 30px;
    background:$c-darkBlueGrey;
    color:$c-white;
  }

  .form-actions {
    font-size:12px;
    float:right;
    text-align:left;
    margin-left:16px;
    margin-top:-4px;
    line-height:1.5;
  }
}

#loginForm.drawer--closed {
  top: -160px;
}

.form-row-clear {
  clear:both;
}

.woocommerce {
  form.half-form {
    .form-row-first {
      float:none;
    }
  }
}

label.error,
.freeform-form-has-errors {
  color:$c-red;
  font-weight:bold;
}

label.nonbold {
  font-weight:400;
}


.fancy-form {
  input[type="text"], input[type="email"], input[type="password"], input[type="search"], textarea, select {
    background-color:$c-offwhite;
  }


  :focus{outline: none;}

  .effect-16{border: 0; padding: 4px 0; border-bottom: 1px solid #ccc; background-color: transparent;}

  .effect-16 ~ .focus-border{position: absolute; bottom: 0; left: 0; width: 0; height: 2px; background-color: #4caf50; transition: 0.4s;}
  .effect-16:focus ~ .focus-border,
  .has-content.effect-16 ~ .focus-border{width: 100%; transition: 0.4s;}
  .effect-16 ~ label{position: absolute; left: 0; width: 100%; top: 9px; color: #aaa; transition: 0.3s; z-index: -1; letter-spacing: 0.5px;}
  .effect-16:focus ~ label, .has-content.effect-16 ~ label{top: -16px; font-size: 12px; color: #4caf50; transition: 0.3s;}
}


input.remove {
  cursor:pointer;
  height:auto;
  padding:0 5px;
  border: 1px solid rgba(54,54,54,.1);
  color: #000 !important;
  font-weight: 900;
  border-radius: 0;

  &:hover {
    background-color: $c-red;
    color:white;
    border:1px solid $c-red;
  }

}