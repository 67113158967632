/*
 * Background image tile
 */

.imageLoader {

	overflow: hidden;
	position: relative;
	transform: translate3d(0, 0, 0);

}

.imageLoader img {
	display: block;
	width: 100%;
}

.imageLoader_image {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	transition: opacity 1s ease-in-out;
}

.imageLoader_image.lazyloaded {
	opacity: 1;
}

.imageLoader_previewImage {
	-webkit-filter: blur(5px);
	filter: blur(5px);
}

/*
 * Background image tile
 */

.imageBackgroundLoader {

	width: 100%;
	height: 100%;
	background-size: cover;
	z-index: 0;

	.imageBackgroundLoader_previewImage {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-color: black;
		z-index: 0;
		-webkit-filter: blur(5px);
		filter: blur(5px);
		transition: opacity 1s ease-in-out;
	}

	&.lazyloaded .imageBackgroundLoader_previewImage {
		opacity: 0;
	}

}
