.tb-menu-control-mobi,
.tb-menu-list .mobile-only { display:none; }
.tb-menu-control-mobi > a {
  font-size:30px;
  padding:0 12px;
  line-height:80px;
  display:block;
}
@media (max-width: 767px) {
  .tb-menu-list .mobile-only {
    display: block;
  }
  .tb-menu-control-mobi {
    display:inline-block;
    vertical-align:middle;
  }

}
@media (min-width: 767px) and (max-width: 880px) {
  .hide-medium { display:none !important;}
}
.tb-header-menu .tb-header-menu-inner {
  position:relative;

}
.tb-header-menu .tb-header-menu-inner .row > div { position:static; }
.header-top-right { padding:0; }
.header-top-right li {
  display:inline-block;
  vertical-align:middle;
  line-height:40px;
}
.header-top-right li a {
  @extend %sansSerif;
  font-weight:400;
  font-size:12px;
  line-height:24px;
  color:$c-darkBlueGrey;
  -webkit-transition:all 0.3s;
  -moz-transition:all 0.3s;
  -o-transition:all 0.3s;
  transition:all 0.3s;
}
.header-top-right li a i {
  color:$c-orange;
  padding-right:7px;
}
.header-top-right li a:hover { color:$c-orange; }
.header-top-right li.map {
  padding:0 20px;
  margin:0 15px;
  position:relative;
}
.header-top-right li.map:before, .header-top-right li.map:after {
  content:"";
  width:1px;
  height:18px;
  background:$c-lightishGrey;
  position:absolute;
  top:50%;
  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  -o-transform:translateY(-50%);
}
.header-top-right li.map:before { left:0; }
.header-top-right li.map:after { right:0; }

@media (min-width: 767px) {
  .tb-menu { text-align:left; }
  .tb-menu-list {
    display:inline-block;
    vertical-align:middle;
    white-space:nowrap;
  }
  .tb-menu-list > ul {
    margin:0;
    padding:0;
    list-style:none;
  }
  .tb-menu-list > ul > li {
    -webkit-transition:all 0.4s;
    -moz-transition:all 0.4s;
    -o-transition:all 0.4s;
    transition:all 0.4s;
    display:inline-block;
  }
  .tb-menu-list > ul > li > a {
    -webkit-transition:all 0.3s;
    -moz-transition:all 0.3s;
    -o-transition:all 0.3s;
    transition:all 0.3s;
    color:#232530;
    display:inline-block;
    font-weight:300;
    position:relative;
    padding:10px 22px 16px 22px !important;
    font-size:15px;
  }
  .tb-menu-list > ul > li > a > span {
    position:relative;
    top:2px;
  }
  .tb-menu-list > ul > li > a:before {
    content:"";
    width:100%;
    height:2px;
    background:$c-orange;
    position:absolute;
    left:0;
    bottom:0;
    opacity:0;
    -webkit-transform:translate3d(0,2px,0);
    -moz-transform:translate3d(0,2px,0);
    -ms-transform:translate3d(0,2px,0);
    -o-transform:translate3d(0,2px,0);
    -webkit-transition:all 0.3s;
    -moz-transition:all 0.3s;
    -o-transition:all 0.3s;
    transition:all 0.3s;
  }
  .tb-menu-list > ul > li.current_page_item > a, .tb-menu-list > ul > li:hover > a, .tb-menu-list > ul > li.current-menu-item > a, .tb-menu-list > ul > li.current_page_parent > a {
    position:relative;
    background:rgba(255,255,255,0.1);
  }
  .tb-menu-list > ul > li.current_page_item > a:before, .tb-menu-list > ul > li:hover > a:before, .tb-menu-list > ul > li.current-menu-item > a:before, .tb-menu-list > ul > li.current_page_parent > a:before {
    opacity:1;
    -webkit-transform:translate3d(0,0,0);
    -moz-transform:translate3d(0,0,0);
    -ms-transform:translate3d(0,0,0);
    -o-transform:translate3d(0,0,0);
  }
  .tb-menu-list > ul > li.menu-item-has-children {
    position:relative;
    /*----basic dropdown----*/
    /*----mega dropdown----*/
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown {
    position:absolute;
    left:0;
    top:100%;
    display:block;
    z-index:1;
    max-height:0;
    display:none;
    border:none;
    -webkit-border-radius:0;
    -moz-border-radius:0;
    border-radius:0;
    box-shadow:none;
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown ul {
    background-color:transparent;
    box-shadow:none;
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown li {
    background-color:$c-white;
    display:block;
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown li:first-child { border:none; }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown li > a {
    -webkit-transition:all 0.4s !important;
    -moz-transition:all 0.4s !important;
    -o-transition:all 0.4s !important;
    transition:all 0.4s !important;
    display:block;
    font-size:13px;
    font-weight:400;
    color:$c-darkBlueGrey;
    line-height:39px;
    padding:0 20px;
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown li:hover > a, .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown li.current_page_item > a, .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown li.current-menu-item > a {
    color:$c-orange;
    background-color:transparent;
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown li:hover > a { padding-left:25px; }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li { display:block; }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li.menu-item-has-children { position:relative; }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li.menu-item-has-children > a:after {
    font-family:FontAwesome;
    content:"\f105";
    font-size:13px;
    position:absolute;
    top:0;
    right:20px;
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li.menu-item-has-children > ul {
    position:absolute;
    top:0;
    left:100%;
    min-width:270px;
    margin:0;
    padding:0;
    border:none;
    -webkit-border-radius:0;
    -moz-border-radius:0;
    border-radius:0;
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li.menu-item-has-children:hover > ul {
    display:block;
    z-index:999;
    -webkit-animation:fadeInUp 0.4s both;
    -moz-animation:fadeInUp 0.4s both;
    -o-animation:fadeInUp 0.4s both;
    animation:fadeInUp 0.4s both;
  }
  .tb-menu-list > ul > li.menu-item-has-children ul.dropdown-menu {
    z-index:999;
    margin-top:0px;
    box-shadow:2px 0 10px rgba(0,0,0,0.09);
  }
  .tb-menu-list > ul > li.menu-item-has-children:hover > ul {
    max-height:1000px;
    display:block;
    -webkit-animation:fadeInUp 0.4s both;
    -moz-animation:fadeInUp 0.4s both;
    -o-animation:fadeInUp 0.4s both;
    animation:fadeInUp 0.4s both;
  }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width { position:static; }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul {
    position:absolute;
    top:100%;
    left:0;
    width:100%;
    margin:0;
    padding:30px 0px 30px;
    border:none;
    -webkit-border-radius:0;
    -moz-border-radius:0;
    border-radius:0;
    display:none;
  }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul.columns6 > li { width:16.666%; }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul.columns4 > li { width:25%; }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li {
    float:left;
    padding-left:40px;
    padding-right:40px;
  }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li > a {
    -webkit-transition:all 0.4s;
    -moz-transition:all 0.4s;
    -o-transition:all 0.4s;
    transition:all 0.4s;
    font-size:18px !important;
    font-weight:400;
    line-height:38px;
    color:#333;
    padding:0px;
  }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li.current_page_item > a, .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li:hover > a {
    background:none;
    color:$c-darkRedGrey;
  }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li.menu-item-has-children > ul {
    display:block;
    position:relative;
    margin:0;
    padding:0;
    border:none;
    -webkit-border-radius:0;
    -moz-border-radius:0;
    border-radius:0;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none;
  }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li.menu-item-has-children > ul > li {
    margin-top:8px;
    margin-bottom:8px;
  }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li.menu-item-has-children > ul > li > a {
    -webkit-transition:all 0.4s !important;
    -moz-transition:all 0.4s !important;
    -o-transition:all 0.4s !important;
    transition:all 0.4s !important;
    display:block;
    font-size:14px !important;
    font-weight:400;
    color:#232530;
    line-height:inherit !important;
    padding:0px;
    -webkit-transform:translate3d(0,0,0);
    -moz-transform:translate3d(0,0,0);
    -ms-transform:translate3d(0,0,0);
    -o-transform:translate3d(0,0,0);
  }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li.menu-item-has-children > ul > li.current_page_item > a, .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li.menu-item-has-children > ul > li:hover > a {
    background:none;
    color:$c-orange !important;
    -webkit-transform:translate3d(5px,0,0);
    -moz-transform:translate3d(5px,0,0);
    -ms-transform:translate3d(5px,0,0);
    -o-transform:translate3d(5px,0,0);
  }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li.menu-item-has-children > ul > li:last-child > a { border-bottom:none; }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li.item-menu-custom-block { padding:14px 20px 8px; }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li.item-menu-custom-block > a { display:none; }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width:hover > ul {
    max-height:1000px;
    display:block;
    -webkit-animation:fadeInUp 0.4s both;
    -moz-animation:fadeInUp 0.4s both;
    -o-animation:fadeInUp 0.4s both;
    animation:fadeInUp 0.4s both;
  }
}

@media (min-width: 767px) {
  .tb-stick-active .tb-header-wrap .tb-header-menu {
    position:fixed;
    top:0;
    left:0;
    right:0;
    width:100%;
    z-index:9999;
  }
  .tb-stick-active .tb-header-wrap .tb-menu-list .menu-bg-logo { display:none; }
  .tb-stick-active .tb-header-wrap.tb-header-v1 .tb-header-menu { background:$c-white; }
  .tb-stick-active .tb-header-wrap.tb-header-v2 .show_all_menu .show_all_cat .get_menu_category { display:none; }
  .tb-stick-active .tb-header-wrap.tb-header-v3 .tb-header-menu {
    position:fixed;
    background:$c-white;
  }
  .tb-stick-active .tb-header-wrap.tb-header-v7 .tb-header-menu { background:$c-white; }
  .tb-stick-active .tb-header-wrap.tb-header-v8 .tb-header-menu { background:$c-white; }
  .tb-stick-active.admin-bar .tb-header-wrap .tb-header-menu { top:32px; }
  .tb-stick-active.admin-bar .tb-header-wrap .tb-menu-sidebar .widget_cart_search_wrap_item .widget_searchform_content.full_search {
    min-height:70px;
    padding-top:10px;
  }
  .tb-stick-active.admin-bar .tb-header-wrap.tb-header-stick .tb-menu-sidebar .widget_cart_search_wrap_item .widget_searchform_content.full_search {
    min-height:112px;
    padding-top:36px;
  }
  .tb-stick-active .tb-header-wrap .tb-header-menu { box-shadow:0px 1px 5px rgba(0,0,0,0.3); }
  .tb-stick-active .tb-header-wrap .tb-menu-sidebar .widget_cart_search_wrap_item .widget_searchform_content.full_search {
    min-height:70px;
    padding-top:18px;
  }
}

.tb-search-bar { padding:30px 24px 0 55px; }
.wrap-mini-cart { line-height:95px; }
.wrap-mini-cart .tb-menu-canvas-wrap, .wrap-mini-cart .widget_mini_cart_wrap {
  display:inline-block;
  vertical-align:middle;
}
.wrap-mini-cart .all_wishlist, .wrap-mini-cart .show-compare {
  display:inline-block;
  vertical-align:middle;
}
.wrap-mini-cart .get_number_wl, .wrap-mini-cart .all_compare, .wrap-mini-cart .icon_cart_wrap {
  position:relative;
  display:inline-block;
  width:40px;
  height:40px;
  line-height:40px;
  text-align:center;
  border:1px solid $c-lightishGrey;
  margin-left:15px;
  -webkit-border-radius:50%;
  -moz-border-radius:50%;
  border-radius:50%;
}
.wrap-mini-cart .get_number_wl i, .wrap-mini-cart .all_compare i, .wrap-mini-cart .icon_cart_wrap i {
  font-size:16px;
  color:#232530;
  -webkit-transition:all 0.4s;
  -moz-transition:all 0.4s;
  -o-transition:all 0.4s;
  transition:all 0.4s;
}
.wrap-mini-cart .get_number_wl span, .wrap-mini-cart .all_compare span, .wrap-mini-cart .icon_cart_wrap span {
  width:16px;
  height:16px;
  line-height:17px;
  text-align:center;
  background:$c-orange;
  font-weight:500;
  color:$c-white;
  display:inline-block;
  font-size:12px;
  position:absolute;
  top:-7px;
  right:-2px;
  -webkit-border-radius:50%;
  -moz-border-radius:50%;
  border-radius:50%;
}
.wrap-mini-cart .get_number_wl:hover i, .wrap-mini-cart .all_compare:hover i, .wrap-mini-cart .icon_cart_wrap:hover i {
  color:$c-orange !important;
  cursor:pointer;
}
.wrap-mini-cart .total_cart {
  @extend %sansSerif;
  font-weight:500;
  font-size:14px;
  vertical-align:middle;
  color:#232530;
  padding-left:5px;
  padding-top:5px;
  display:inline-block;
}
.wrap-mini-cart .full-cart { display:flex; }
.wrap-mini-cart .full-cart .header {
  display:inline-block;
  line-height:normal;
  border:1px solid rgba(37,37,37,0.1);
  padding-right:15px;
}
.wrap-mini-cart .full-cart .header .icon_cart_wrap {
  border:0px;
  -webkit-border-radius:0px;
  -moz-border-radius:0px;
  border-radius:0px;
  width:auto;
  float:left;
  display:inline-block;
  margin-left:0px;
}
.wrap-mini-cart .full-cart .header .icon_cart_wrap i {
  width:40px;
  line-height:40px;
  border-right:1px solid rgba(37,37,37,0.1);
}
.wrap-mini-cart .full-cart .header .icon_cart_wrap .cart_total {
  background:none;
  color:#333;
  position:relative;
  width:auto;
  font-size:14px;
  padding-left:15px;
  line-height:40px;
  top:-1px;
  height:inherit;
}
.wrap-mini-cart .full-cart .header .icon_cart_wrap .cart_total:after { content:' Item(s)'; }
.wrap-mini-cart .full-cart .header .total_cart {
  padding-top:0px;
  line-height:40px;
  float:left;
}
.wrap-mini-cart .full-cart .header .total_cart:before {
  content:':';
  margin-right:5px;
}
@media screen and (max-width: 1199px) {
  .wrap-mini-cart .get_number_wl, .wrap-mini-cart .all_compare, .wrap-mini-cart .icon_cart_wrap { margin-left:5px !important; }
}
.widget-woocommerce-currency-switcher {
  display:inline-block;
  vertical-align:middle;
  height:auto;
  line-height:40px;
}
.widget-woocommerce-currency-switcher img {
  max-width:100%;
  width:auto;
  margin-top:5px;
}
.widget-woocommerce-currency-switcher form { position:relative; }
.widget-woocommerce-currency-switcher form .dd-container .dd-select {
  background:none !important;
  position:relative;
  border:none;
}
.widget-woocommerce-currency-switcher form .dd-container .dd-select .dd-selected {
  padding:0;
  border:none;
  background:none;
  text-align:left;
  padding:0 15px 0 0;
}
.widget-woocommerce-currency-switcher form .dd-container .dd-select .dd-selected .dd-selected-text {
  @extend %sansSerif;
  font-weight:400;
  text-transform:capitalize;
  color:$c-darkBlueGrey;
  font-size:12px;
  line-height:40px;
  display:block;
  text-align:left;
}
.widget-woocommerce-currency-switcher form .dd-container .dd-select .dd-selected .dd-selected-text:hover {
  cursor:pointer;
  color:$c-orange;
}
.widget-woocommerce-currency-switcher form .dd-container .dd-select .dd-selected .dd-selected-description { display:none; }
.widget-woocommerce-currency-switcher form .dd-container .dd-select .dd-pointer {
  border:0px;
  margin-top:0px;
  right:12px;
  top:0px;
}
.widget-woocommerce-currency-switcher form .dd-container .dd-select .dd-pointer:before {
  content:"\f107";
  font-family:"FontAwesome";
}
.widget-woocommerce-currency-switcher form .dd-container .dd-select .dd-pointer-up { border:0px !important; }
.widget-woocommerce-currency-switcher form .dd-container .dd-select .dd-pointer-up:before {
  content:"\f106";
  font-family:"FontAwesome";
}
.widget-woocommerce-currency-switcher form .dd-container .dd-options {
  width:100%;
  min-width:70px;
  text-align:left;
  padding:0 10px;
}
.widget-woocommerce-currency-switcher form .dd-container .dd-options li { width:auto; }
.widget-woocommerce-currency-switcher form .dd-container .dd-options li a {
  @extend %sansSerif;
  font-weight:400;
  text-transform:uppercase;
  color:$c-body;
  font-size:13px;
  line-height:24px;
  padding:5px;
  background-color:$c-white;
}
.widget-woocommerce-currency-switcher form .dd-container .dd-options li a .dd-option-description { display:none; }
.widget-woocommerce-currency-switcher form .dd-container .dd-options li a:hover {
  color:$c-orange;
  cursor:pointer;
}
.widget-woocommerce-currency-switcher form .dd-container .dd-options li a .dd-option-text:hover {
  color:$c-orange;
  cursor:pointer;
}
.widget-woocommerce-currency-switcher form select {
  -webkit-appearance:none;
  -moz-appearance:none;
  appearande:none;
  padding:0;
  background-color:$c-nearBlack;
  border:none;
  color:$c-white;
  background:transparent !important;
}
.widget-woocommerce-currency-switcher form .woocommerce-currency-switcher {
  text-transform:uppercase;
  @extend %sansSerif;
}
.widget-woocommerce-currency-switcher:last-child form:before {
  position:absolute;
  height:10px;
  width:1px;
  content:"";
  border-left:1px solid $c-white;
  top:13px;
  left:0;
}
.widget-woocommerce-currency-switcher:first-child form { padding-left:0; }
.widget-woocommerce-currency-switcher:first-child form:before { display:none; }
.tb-header-wrap .tb-sidebar >.widget {
  padding-right:18px;
  padding-left:18px;
  font-size:12px;
}

.tb-header-wrap .tb-sidebar >.widget:after {
  content:"";
  width:1px;
  height:24px;
  background:$c-lightGrey;
  position:absolute;
  top:50%;
  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  -o-transform:translateY(-50%);
  right:0px;
}
/*.tb-header-wrap .tb-sidebar.tb-sidebar-left >.widget:first-child { padding-left:0px; }*/
.tb-header-wrap .tb-sidebar >.widget:last-child:after { display:none; }
.tb-header-wrap .tb-sidebar >.widget p { margin-bottom:0px !important; }

.tb-header-wrap .tb-sidebar >.widget a {
  color:$c-body;
}
.tb-header-wrap .tb-sidebar >.widget a:hover {
  cursor:pointer;
  color:$c-orange;
  text-decoration: none;
}
.tb-header-wrap .tb-sidebar .widget {
  display:inline-block;
  position:relative;
}
.tb-header-wrap .tb-sidebar .widget .fa {
  font-size:16px;
  color:$c-orange;
  margin-right:5px;
}
.tb-header-wrap .tb-sidebar .fb-widget {
  padding-left:12px;
}
.tb-header-wrap .tb-sidebar .fb-widget .fb-icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: $facebookBlue;
  text-align: center;
  margin-right: 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: $c-white;
  font-size:18px;
  display:block;
}
.tb-header-wrap .tb-sidebar .widget .fb-icon .fa {
  color:$c-white;
  margin-right:0;
}

.tb-header-wrap .tb-sidebar .widget .icon_cart_wrap .cart_total {
  position: relative;
  top: -8px;
  right:-3px;
  background: $c-red;
  padding: 2px 8px;
  border-radius: 10px;
  color: $c-white;
  font-size: 0.8em;
}


.tb-header-top {
  border-bottom:1px solid $c-lightishGrey;
  z-index: 999;
  position: absolute;
  opacity:.99;
}

.tb-header-top .tb-sidebar-right .widget {
  text-align:right;
}
.tb-header-top .menu li {
  display:inline-block;
  vertical-align:middle;
  padding-left:20px;
  margin-left:20px;
  position:relative;
}
.tb-header-top .menu li a {
  @extend %sansSerif;
  font-weight:400;
  font-size:12px;
  color:$c-darkBlueGrey;
  line-height:40px;
  text-transform:capitalize;
}
.tb-header-top .menu li a:hover {
  cursor:pointer;
  color:$c-orange;
}
.tb-header-top .menu li:before {
  content:"";
  width:1px;
  height:20px;
  background:$c-lightishGrey;
  position:absolute;
  top:50%;
  left:0;
  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  -o-transform:translateY(-50%);
}
.tb-header-top .menu:first-child:before { display:none; }
@media screen and (max-width: 767px) {
  .tb-header-top .contact-info .tb-sidebar-left { display:none; }
  /*.tb-header-top .login-info { margin:0 auto; }*/
  /*.tb-header-top .login-info .tb-sidebar-right { text-align:center; }*/
}

.show_all_menu { position:relative; }
.show_all_menu .show_all_cat .click_cat_all {
  width:100%;
  height:50px;
  display:block;
  background:#232530;
  font-size:14px;
  line-height:46px;
  padding-left:22px;
}
.show_all_menu .show_all_cat .click_cat_all i, .show_all_menu .show_all_cat .click_cat_all .accessories {
  display:inline-block;
  vertical-align:middle;
  font-weight:500;
  color:$c-white;
  -webkit-transition:all 0.3s;
  -moz-transition:all 0.3s;
  -o-transition:all 0.3s;
  transition:all 0.3s;
}
.show_all_menu .show_all_cat .click_cat_all .accessories {
  margin-bottom:0;
  text-transform:uppercase;
  @extend %sansSerif;
  padding-left:12px;
}
.show_all_menu .show_all_cat .get_menu_category {
  position:absolute;
  top:100%;
  left:0;
  width:100%;
  background:$c-white;
  z-index:9;
}
.show_all_menu .show_all_cat .get_menu_category ul {
  border:1px solid $c-lightishGrey;
  border-top:none;
  padding:0;
  max-height:520px;
  overflow-x:hidden;
  /* width */
  overflow-y:auto;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.show_all_menu .show_all_cat .get_menu_category ul::-webkit-scrollbar { width:10px; }
.show_all_menu .show_all_cat .get_menu_category ul::-webkit-scrollbar-track { box-shadow:inset 0 0 5px $c-orange; }
.show_all_menu .show_all_cat .get_menu_category ul::-webkit-scrollbar-thumb { background:$c-orange; }
.show_all_menu .show_all_cat .get_menu_category ul::-webkit-scrollbar-thumb:hover { background:$c-orange; }
.show_all_menu .show_all_cat .get_menu_category ul li {
  border-bottom:1px solid $c-lightishGrey;
  box-sizing:border-box;
}
.show_all_menu .show_all_cat .get_menu_category ul li:last-child { border-bottom:none; }
.show_all_menu .show_all_cat .get_menu_category ul li a {
  @extend %sansSerif;
  font-weight:400;
  font-size:14px;
  line-height:39px;
  display:block;
  text-transform:capitalize;
  color:#232530;
  padding-left:20px;
  -webkit-transform:translate3d(0,0,0);
  -moz-transform:translate3d(0,0,0);
  -ms-transform:translate3d(0,0,0);
  -o-transform:translate3d(0,0,0);
  -webkit-transition:all 0.3s;
  -moz-transition:all 0.3s;
  -o-transition:all 0.3s;
  transition:all 0.3s;
}
.show_all_menu .show_all_cat .get_menu_category ul li a i {
  color:$c-orange;
  margin-right:15px;
}
.show_all_menu .show_all_cat .get_menu_category ul li a:hover {
  color:$c-orange;
  -webkit-transform:translate3d(5px,0,0);
  -moz-transform:translate3d(5px,0,0);
  -ms-transform:translate3d(5px,0,0);
  -o-transform:translate3d(5px,0,0);
}
@media screen and (max-width: 1199px) {
  .show_all_menu .show_all_cat .get_menu_category { display:none; }
}
.tb-header-wrap .search-tog { display:none; }
.tb-header-wrap.tb-header-fixed {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  z-index:999;
}
.tb-header-wrap .tb-header-top {
  padding:10px 0;
  width: 100%;
}
.tb-header-wrap .tb-header-top .tb-sidebar.tb-sidebar-left a.text-underline {
  font-size:12px;
  text-decoration:underline;
  color:#f0513c;
  text-underline-position:under;
}
.tb-header-wrap .tb-header-top .tb-sidebar .header-top-left {
  padding:0;
  text-align:left;
}
.tb-header-wrap .tb-header-top .tb-sidebar .header-top-left li {
  position:relative;
  display:inline-block;
  vertical-align:middle;
}
.tb-header-wrap .tb-header-top .tb-sidebar .header-top-left li a {
  @extend %sansSerif;
  font-weight:400;
  font-size:13px;
  line-height:50px;
  color:$c-darkBlueGrey;
  -webkit-transition:all 0.3s;
  -moz-transition:all 0.3s;
  -o-transition:all 0.3s;
  transition:all 0.3s;
}
.tb-header-wrap .tb-header-top .tb-sidebar .header-top-left li a i { padding-right:10px; }
.tb-header-wrap .tb-header-top .tb-sidebar .header-top-left li a:hover { color:$c-orange; }
.tb-header-wrap .tb-header-top .tb-sidebar .header-top-left li:before {
  content:"";
  width:1px;
  height:24px;
  background:$c-lightGrey;
  position:absolute;
  top:50%;
  right:0;
  transform:translateY(-50%);
}
.tb-header-wrap .tb-header-top .tb-sidebar .header-top-left li:first-child { padding-right:20px; }
.tb-header-wrap .tb-header-top .tb-sidebar .header-top-left li:last-child { padding-left:15px; }
.tb-header-wrap .tb-header-top .tb-sidebar .header-top-left li:last-child:before { display:none; }

.tb-header-wrap .tb-header-center {
  padding: 75px 0 10px;

}
body.home .tb-header-wrap .tb-header-center {
}

.tb-header-wrap .tb-logo > a { display:block; }
.tb-header-wrap .tb-logo > a > img {
  position:relative;
}
.tb-header-wrap .tb-logo .logo-line {
  border-bottom:4px solid $c-red;
}
.tb-header-wrap .tb-logo .logo-slogan {
  @extend %serif;
  font-size:14.7px;
  font-weight:600;
  margin-top:4px;
}


.tb-header-wrap.tb-header-v1 .tb-header-top .tb-sidebar-left p { margin-bottom:0 !important; }
.tb-header-wrap.tb-header-v1 .phone-map {
  margin:0;
  padding:0;
  line-height:50px;
}
.tb-header-wrap.tb-header-v1 .phone-map li {
  display:inline-block;
  vertical-align:middle;
}
.tb-header-wrap.tb-header-v1 .phone-map li i {
  width:30px;
  height:30px;
  line-height:30px;
  background:$c-orange;
  text-align:center;
  margin-right:5px;
  -webkit-border-radius:50%;
  -moz-border-radius:50%;
  border-radius:50%;
  color:$c-white;
}
.tb-header-wrap.tb-header-v1 .phone-map li span {
  @extend %sansSerif;
  font-weight:500;
  font-size:14px;
  line-height:24px;
  color:#232530;
}
.tb-header-wrap.tb-header-v1 .phone-map li:last-child { margin-left:25px; }
.tb-header-wrap.tb-header-v1 .tb-header-menu { background:#eceff6; }
@media screen and (max-width: 1199px) {
  .tb-header-wrap.tb-header-v1 .phone-map li span { font-size:12px; }
}
@media screen and (max-width: 767px) {
  .tb-header-wrap.tb-header-v1 .tb-menu-control-mobi > a { line-height:50px; }
  .tb-header-wrap.tb-header-v1 .tb-menu-list {
    left:0px;
    right:auto;
  }
}
@media screen and (max-width: 767px) {
  .tb-header-wrap.tb-header-v1 .tb-logo >a >img {
    position:relative;
    display:block;
    top:0px;
    margin:15px auto;
  }
  .tb-header-wrap.tb-header-v1 .tb-header-center .tb-search-bar { padding:0px; }
  .tb-header-wrap.tb-header-v1 .tb-header-center .tb-search-bar #tb-mega-searchform {
    /*display:none;
    position:absolute;
    top:52px;
    left:0px;*/
    width:98%;
    margin:20px auto;
  }
  .tb-header-wrap.tb-header-v1 .tb-header-center .tb-search-bar #tb-mega-searchform #searchsubmit { width:38px; }
  .tb-header-wrap.tb-header-v1 .tb-header-center .tb-search-bar #tb-mega-searchform #searchsubmit:before {
    font-size:14px;
    content:"\f002";
    font-family:'FontAwesome';
  }
  .tb-header-wrap.tb-header-v1 .tb-header-center .tb-search-bar #tb-mega-searchform #searchsubmit span { display:none; }
  .tb-header-wrap.tb-header-v1 .tb-header-center .tb-search-bar #tb-mega-searchform .col-xs-25 { display:none; }
  .tb-header-wrap.tb-header-v1 .tb-header-center .tb-search-bar #tb-mega-searchform .col-xs-95 { width:100%; }
  .tb-header-wrap.tb-header-v1 .tb-header-center .tb-search-bar .search-tog {
    position:absolute;
    z-index:9;
    right:15px;
    top:8px;
    display:inline-block;
    width:38px;
    height:38px;
    line-height:38px;
    text-align:center;
    border:1px solid $c-orange;
    background:$c-white;
    margin-left:15px;
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    border-radius:50%;
  }
  .tb-header-wrap.tb-header-v1 .tb-header-center .tb-search-bar .search-tog i {
    font-size:14px;
    color:$c-orange;
    -webkit-transition:all 0.4s;
    -moz-transition:all 0.4s;
    -o-transition:all 0.4s;
    transition:all 0.4s;
  }
  .tb-header-wrap.tb-header-v1 .tb-header-center .tb-menu-sidebar {
    position:absolute;
    right:62px;
    z-index:9;
  }
  .tb-header-wrap.tb-header-v1 .tb-header-center .tb-menu-sidebar .wrap-mini-cart { line-height:52px; }
  .tb-header-wrap.tb-header-v1 .tb-header-center .tb-menu-sidebar .wrap-mini-cart .total_cart { display:none; }
  .tb-header-wrap.tb-header-v1 .tb-header-center .tb-menu-sidebar .wrap-mini-cart .all_wishlist a.get_number_wl, .tb-header-wrap.tb-header-v1 .tb-header-center .tb-menu-sidebar .wrap-mini-cart .all_compare, .tb-header-wrap.tb-header-v1 .tb-header-center .tb-menu-sidebar .wrap-mini-cart .icon_cart_wrap {
    background:$c-white;
    width:38px;
    line-height:38px;
    height:38px;
    border-color:$c-orange;
  }
  .tb-header-wrap.tb-header-v1 .tb-header-center .tb-menu-sidebar .wrap-mini-cart .all_wishlist a.get_number_wl i, .tb-header-wrap.tb-header-v1 .tb-header-center .tb-menu-sidebar .wrap-mini-cart .all_compare i, .tb-header-wrap.tb-header-v1 .tb-header-center .tb-menu-sidebar .wrap-mini-cart .icon_cart_wrap i {
    color:$c-orange;
    font-size:14px;
  }
  .tb-header-wrap.tb-header-v1 .tb-header-menu .tb-menu-sidebar-right { display:none; }
  .tb-header-wrap.tb-header-v1 .tb-header-menu .tb-menu-control-mobi { float:left; }
  .tb-header-wrap.tb-header-v1 .tb-header-menu .tb-menu-control-mobi >a { padding:0px; }
  .tb-header-wrap.tb-header-v1 .tb-header-menu .tb-menu-control-mobi i { font-size:30px; }
}

@media screen and (max-width: 767px) {
  .tb-header-wrap .tb-menu-control-mobi {
    /*float:right;*/
    position: absolute;
    top: 0;
    left: 5px;
  }
  .tb-header-wrap .tb-menu-control-mobi > a { line-height:45px; }
}
@media (max-width: 767px) {
  .tb-menu-list {
    display:none;
    position:absolute;
    /*top:100%;*/
    top:-288px;
    right:0;
    min-width:270px;
    width:100%;
    text-align:left;
    background:$c-black;
    padding:10px 0;
  }
  .tb-menu-list.active {
    display:block;
    z-index:999;
    -webkit-animation:fadeInUp 0.4s both;
    -moz-animation:fadeInUp 0.4s both;
    -o-animation:fadeInUp 0.4s both;
    animation:fadeInUp 0.4s both;
  }
  .tb-menu-list > ul {
    margin:0;
    padding:0;
    list-style:none;
  }
  .tb-menu-list > ul > li {
    -webkit-transition:all 0.4s;
    -moz-transition:all 0.4s;
    -o-transition:all 0.4s;
    transition:all 0.4s;
    display:block;
  }
  .tb-menu-list > ul > li > a {
    font-size:14px;
    color:$c-white;
    display:block;
    line-height:38px;
    text-transform:uppercase;
    padding:0 15px;
  }

  .tb-menu-list > ul > li.menu-item-has-children {
    display:none !important;
  }


  .tb-menu-list > ul > li.current_page_item, .tb-menu-list > ul > li:hover { background:rgba(255,255,255,0.1); }
  .tb-menu-list > ul > li.menu-item-has-children {
    position:relative;
    /*----basic dropdown----*/
    /*----mega dropdown----*/
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown {
    position:relative;
    min-width:270px;
    margin:0;
    padding:0;
    border:none;
    -webkit-border-radius:0;
    -moz-border-radius:0;
    border-radius:0;
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li {
    display:block;
    border-top:1px solid rgba(170,170,170,0.2);
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li:first-child { border:none; }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li > a {
    -webkit-transition:all 0.4s !important;
    -moz-transition:all 0.4s !important;
    -o-transition:all 0.4s !important;
    transition:all 0.4s !important;
    display:block;
    font-size:13px;
    font-weight:400;
    color:#747474;
    line-height:38px;
    padding:0 20px;
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li.menu-item-has-children { position:relative; }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li.menu-item-has-children > a:after {
    font-family:FontAwesome;
    content:"\f105";
    font-size:13px;
    position:absolute;
    top:0;
    right:20px;
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li.menu-item-has-children > ul {
    position:relative;
    min-width:270px;
    margin:0;
    padding:0;
    border:none;
    -webkit-border-radius:0;
    -moz-border-radius:0;
    border-radius:0;
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li.menu-item-has-children > ul > li {
    display:block;
    border-top:1px solid rgba(170,170,170,0.2);
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li.menu-item-has-children > ul > li:first-child { border:none; }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li.menu-item-has-children > ul > li > a {
    -webkit-transition:all 0.4s;
    -moz-transition:all 0.4s;
    -o-transition:all 0.4s;
    transition:all 0.4s;
    display:block;
    font-size:13px;
    font-weight:400;
    color:#747474;
    line-height:38px;
    padding:0 20px;
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li.menu-item-has-children > ul > li.current_page_item > a, .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li.menu-item-has-children > ul > li:hover > a {
    background:none;
    color:$c-orange;
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li.menu-item-has-children:hover > ul {
    display:block;
    z-index:999;
    -webkit-animation:fadeInUp 0.4s both;
    -moz-animation:fadeInUp 0.4s both;
    -o-animation:fadeInUp 0.4s both;
    animation:fadeInUp 0.4s both;
  }
  .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li.current_page_item > a, .tb-menu-list > ul > li.menu-item-has-children > ul.standar-dropdown > li:hover > a {
    background:none;
    color:$c-orange;
  }
  .tb-menu-list > ul > li.menu-item-has-children:hover > ul {
    display:block;
    z-index:999;
    -webkit-animation:fadeInUp 0.4s both;
    -moz-animation:fadeInUp 0.4s both;
    -o-animation:fadeInUp 0.4s both;
    animation:fadeInUp 0.4s both !important;
  }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width { position:static; }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul {
    position:relative;
    width:100%;
    max-width:270px;
    margin:0;
    padding:20px 10px;
    border:none;
    -webkit-border-radius:0;
    -moz-border-radius:0;
    border-radius:0;
  }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li > a {
    -webkit-transition:all 0.4s !important;
    -moz-transition:all 0.4s !important;
    -o-transition:all 0.4s !important;
    transition:all 0.4s !important;
    font-size:14px;
    font-weight:400;
    line-height:38px;
    color:#474747;
    text-transform:uppercase;
    padding:0 15px;
  }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li.current_page_item > a, .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li:hover > a {
    background:none;
    color:$c-orange;
  }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li.menu-item-has-children > ul {
    position:relative;
    margin:0;
    padding:0;
    border:none;
    -webkit-border-radius:0;
    -moz-border-radius:0;
    border-radius:0;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none;
  }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li.menu-item-has-children > ul > li > a {
    -webkit-transition:all 0.4s;
    -moz-transition:all 0.4s;
    -o-transition:all 0.4s;
    transition:all 0.4s;
    display:block;
    font-size:13px;
    font-weight:400;
    color:#747474;
    line-height:38px;
    padding:0 20px;
  }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li.menu-item-has-children > ul > li.current_page_item > a, .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li.menu-item-has-children > ul > li:hover > a {
    background:none;
    color:$c-orange;
    padding-left:25px;
  }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li.menu-item-has-children:hover > ul {
    display:block;
    z-index:999;
    -webkit-animation:fadeInUp 0.4s both;
    -moz-animation:fadeInUp 0.4s both;
    -o-animation:fadeInUp 0.4s both;
    animation:fadeInUp 0.4s both;
  }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li.item-menu-custom-block { padding:10px 15px; }
  .tb-menu-list > ul > li.menu-item-has-children.has_full_width > ul > li.item-menu-custom-block > a { display:none; }
}

#nav > li > a,
a.icon_search_wrap,
a.icon_cart_wrap,
.header-menu-item-icon a {
  font-family:Poppins;
  font-style:normal;
  font-size:13px;
  /*opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.24s ease-in-out;
  -moz-transition: opacity 0.24s ease-in-out;
  transition: opacity 0.24s ease-in-out;*/
}
/*.wf-loading #nav > li > a, a.icon_search_wrap, a.icon_cart_wrap, .header-menu-item-icon a{
  opacity: 0;
}
.ie.wf-loading #nav > li > a, a.icon_search_wrap, a.icon_cart_wrap, .header-menu-item-icon a{
  visibility: hidden;
}
#nav > li > ul li a{
  font-family:Poppins;
  line-height:32px;
  font-style:normal;
  font-size:13px;
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.24s ease-in-out;
  -moz-transition: opacity 0.24s ease-in-out;
  transition: opacity 0.24s ease-in-out;
}
.wf-loading #nav > li > ul li a{
  opacity: 0;
}
.ie.wf-loading #nav > li > ul li a{
  visibility: hidden;
}*/
#nav > li > a{
  padding-top:0;
  padding-right:18px;
  padding-bottom:0;
  padding-left:18px;
  font-size:15px;

  &:hover {
    text-decoration: none !important;
  }
}

.email-link .short-address { display:none;}

@media (max-width: 1200px) {
  .email-link .full-address {
    display: none;
  }
  .email-link .short-address {
    display: inline-block;
  }
}
@media (max-width: 1200px) and (min-width:767px) {
  .tb-header-wrap .tb-logo .logo-slogan {
    font-size: 12px;
  }
}

@media (max-width: 990px) {
  #tb-mega-searchform .col-xs-25 {
    display: none;
    width:0px;
  }
  #tb-mega-searchform .col-xs-95 {
    width:100% !important;
  }

}