#instafeed a {
  display:inline-block;
  float:left;
  background:#232530;
  position:relative;
  overflow:hidden;
}
#instafeed a img {
  -webkit-transition:all 0.4s;
  -moz-transition:all 0.4s;
  -o-transition:all 0.4s;
  transition:all 0.4s;
}
#instafeed a .hover-effect {
  position:absolute;
  top:40%;
  width:100%;
  text-align:center;
  -webkit-animation:fadeOutDown 0.4s both;
  -moz-animation:fadeOutDown 0.4s both;
  -o-animation:fadeOutDown 0.4s both;
  animation:fadeOutDown 0.4s both;
}
#instafeed a .hover-effect i {
  color:$c-white;
  font-size:16px;
}
#instafeed a .hover-effect span {
  display:block;
  /* text color */
  color:$c-white;
  font-size:12px;
  text-transform:uppercase;
}
#instafeed a:hover img { opacity:0.3; }
#instafeed a:hover .hover-effect {
  -webkit-transform:translate(-50%,-50%);
  -moz-transform:translate(-50%,-50%);
  -ms-transform:translate(-50%,-50%);
  -o-transform:translate(-50%,-50%);
  -webkit-animation:fadeInUp 0.4s both;
  -moz-animation:fadeInUp 0.4s both;
  -o-animation:fadeInUp 0.4s both;
  animation:fadeInUp 0.4s both;
}

.null-instagram-feed ul {
  margin:0 auto;
  text-align:center;
  padding:0;
}
.null-instagram-feed ul li {
  position:relative;
  display:inline-block;
  vertical-align:middle;
}
.null-instagram-feed ul li > a {
  display:block;
  overflow:hidden;
}
.null-instagram-feed ul li > a > img {
  display:block;
  max-width:275px;
  height:auto;
  -webkit-transform:scale3d(1,1,1);
  -moz-transform:scale3d(1,1,1);
  -ms-transform:scale3d(1,1,1);
  -o-transform:scale3d(1,1,1);
  -webkit-transition:all 0.3s;
  -moz-transition:all 0.3s;
  -o-transition:all 0.3s;
  transition:all 0.3s;
}
.null-instagram-feed ul li > a:before {
  content:"";
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.3);
  position:absolute;
  top:0;
  left:0;
  z-index:-1;
  opacity:0;
  -webkit-transition:all 0.3s;
  -moz-transition:all 0.3s;
  -o-transition:all 0.3s;
  transition:all 0.3s;
}
.null-instagram-feed ul li .content_textall {
  position:absolute;
  top:50%;
  left:50%;
  min-width:150px;
  -webkit-transform:translate(-50%,-50%);
  -moz-transform:translate(-50%,-50%);
  -ms-transform:translate(-50%,-50%);
  -o-transform:translate(-50%,-50%);
  z-index:3;
  -webkit-transition:all 0.3s;
  -moz-transition:all 0.3s;
  -o-transition:all 0.3s;
  transition:all 0.3s;
}
.null-instagram-feed ul li .content_textall > a {
  @extend %sansSerif;
  font-weight:400;
  font-size:12px;
  line-height:21px;
  text-transform:uppercase;
  color:$c-white;
  opacity:0;
  z-index:-2;
  -webkit-transform:translate3d(0,20px,0);
  -moz-transform:translate3d(0,20px,0);
  -ms-transform:translate3d(0,20px,0);
  -o-transform:translate3d(0,20px,0);
  -webkit-transition:all 0.3s;
  -moz-transition:all 0.3s;
  -o-transition:all 0.3s;
  transition:all 0.3s;
}
.null-instagram-feed ul li .content_textall > a span {
  font-size:16px;
  display:block;
  margin-bottom:10px;
  opacity:0;
  z-index:-2;
  -webkit-transition:all 0.3s;
  -moz-transition:all 0.3s;
  -o-transition:all 0.3s;
  transition:all 0.3s;
}
.null-instagram-feed ul li:hover > a:before {
  opacity:1;
  z-index:2;
}
.null-instagram-feed ul li:hover > a img {
  -webkit-transform:scale3d(1.1,1.1,1.1);
  -moz-transform:scale3d(1.1,1.1,1.1);
  -ms-transform:scale3d(1.1,1.1,1.1);
  -o-transform:scale3d(1.1,1.1,1.1);
}
.null-instagram-feed ul li:hover .content_textall > a {
  opacity:1;
  z-index:3;
  -webkit-transform:translate3d(0,0,0);
  -moz-transform:translate3d(0,0,0);
  -ms-transform:translate3d(0,0,0);
  -o-transform:translate3d(0,0,0);
}
.null-instagram-feed ul li:hover .content_textall > a span {
  opacity:1;
  z-index:3;
  -webkit-transform:translate3d(0,0,0);
  -moz-transform:translate3d(0,0,0);
  -ms-transform:translate3d(0,0,0);
  -o-transform:translate3d(0,0,0);
}
.change_margin_instagram .wpb_widgetised_column.wpb_content_element { margin-bottom:0; }
@media (min-width: 1300px) {
  .null-instagram-feed ul li {
    max-width:193px;
    height:auto;
  }
}
@media (min-width: 992px) and (max-width: 1299px) {
  .null-instagram-feed ul li {
    max-width:172px;
    height:auto;
  }
}
@media (max-width: 767px) {
  #instafeed a { width:33.333%; }
}
@media (max-width: 480px) {
  #instafeed a { width:50%; }
}