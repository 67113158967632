.__mTop {
	margin-top: $wideMargin;
}

.__mTop__wee {
	margin-top: $defaultMargin;
}
.jws_theme_footer,
.__mTop__wide {
	margin-top: $wideMargin*2;
}
.__pTop {
	padding-top: $wideMargin;
}
.__pTop__wide {
	padding-top: $wideMargin*2;
}

.__mBottom {
	margin-bottom: $wideMargin;
}
.__mBottom__wide {
	margin-bottom: $wideMargin*2;
}
.__mBottom__wee {
	margin-bottom: 5px;
}
.__pBottom {
	padding-bottom: $wideMargin;
}
.__pBottom__wide {
	padding-bottom: $wideMargin*2;
}
.__pBottom__wee {
	padding-bottom: $defaultMargin;
}

.__mV {
	margin-top: $wideMargin;
	margin-bottom: $wideMargin;
}

.__mV__wee {
	margin-top: $defaultMargin;
	margin-bottom: $defaultMargin;
}

.font-smallest {
	font-size:.7em !important;
}
.font-smaller {
	font-size:.8em !important;
}
.font-small {
	font-size:.9em !important;
}
.all-caps {
	text-transform: uppercase;
}
.column-note {
	position: absolute;
	left: 65px;
	padding-top: 28px;

}
@media (max-width: 767px) {
	.column-note {
		display: none;
	}
}

body.bundle-product .tb-content ol,
body.bundle-product1 .tb-content ol,
ul.two-columns {
	columns: 2;
	-webkit-columns: 2;
	-moz-columns: 2;
	-webkit-column-gap: 40px;
	-moz-column-gap: 40px;
	column-gap: 40px;
}

ul.three-columns {
	columns: 3;
	-webkit-columns: 3;
	-moz-columns: 3;
	-webkit-column-gap: 20px;
	-moz-column-gap: 20px;
	column-gap: 20px;
}

ul.no-style {
	list-style: none !important;

	li {
		list-style: none !important;
	}
}

body.bundle-product .tb-content ol li {
	list-style-position: inside !important;
}

.float-right {
	float:right;
}
.float-left {
	float:left;
}

.center-xs {
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
}

.spaceNone {
	padding:0;
	margin:0;
}