.comments-area .comments-title { margin-bottom:36px; }
.comments-area .comments-title small { text-transform:none; }
.comments-area .comments-title legend {
  display:inline-block;
  @extend %sansSerif;
  font-weight:300;
  font-size:24px;
  line-height:24px;
  color:#232530;
  text-transform:capitalize;
}
.comments-area .comment-list {
  padding-left:0;
  margin-bottom:20px;
}
.comments-area .comment-list li {
  padding:0 !important;
  border-top:none !important;
  list-style:none;
}
.comments-area .comment-list li ol.children { padding-left:100px; }
.comments-area .comment-list li ol.children li .comment-body { margin-top:8px; }
.comments-area .comment-list li .comment-body {
  position:relative;
  margin-top:28px;
  margin-bottom:30px;
}
.comments-area .comment-list li .comment-body .comment-avatar {
  position:absolute;
  left:0;
  top:0;
}
.comments-area .comment-list li .comment-body .comment-avatar img {
  width:70px;
  height:70px;
  -webkit-border-radius:50%;
  -moz-border-radius:50%;
  border-radius:50%;
  margin:0;
}
.comments-area .comment-list li .comment-body .comment-info {
  position:relative;
  padding:0 0px 0px 130px;
}
.comments-area .comment-list li .comment-body .comment-info .comment-header-info {
  position:relative;
  margin-bottom:6px;
}
.comments-area .comment-list li .comment-body .comment-info .comment-header-info > div { display:inline-block; }
.comments-area .comment-list li .comment-body .comment-info .comment-header-info .comment-author {
  font-size:16px;
  text-transform:capitalize;
  @extend %sansSerif;
  font-weight:400;
  line-height:24px;
  color:#232530;
  margin-right:15px;
  margin-top:0px;
  margin-bottom:12px;
}
.comments-area .comment-list li .comment-body .comment-info .comment-header-info .comment-date {
  display:block;
  top:-6px;
  position:relative;
}
.comments-area .comment-list li .comment-body .comment-info .comment-header-info .comment-date a {
  @extend %sansSerif;
  font-weight:300;
  font-size:14px;
  line-height:24px;
  color:$c-green;
  margin-right:30px;
}
.comments-area .comment-list li .comment-body .comment-info .comment-header-info .comment-date a:hover { color:$c-darkRedGrey; }
.comments-area .comment-list li .comment-body .comment-info .comment-header-info .reply {
  position:absolute;
  top:5px;
  right:0;
}
.comments-area .comment-list li .comment-body .comment-info .comment-header-info .reply a {
  @extend %sansSerif;
  font-weight:500;
  font-size:12px;
  line-height:24px;
  text-transform:uppercase;
  color:$c-black;
  letter-spacing:1px;
  border:1px solid $c-green;
  padding:5px 25px;
  -webkit-transition:all 0.3s;
  -moz-transition:all 0.3s;
  -o-transition:all 0.3s;
  transition:all 0.3s;
}
.comments-area .comment-list li .comment-body .comment-info .comment-header-info .reply a:hover {
  border-color:$c-green;
  background:$c-green;
  color:$c-white;
}
.comments-area .comment-list li .comment-body .comment-info p {
  @extend %sansSerif;
  font-weight:400;
  font-size:14px;
  line-height:26px;
  color:$c-darkBlueGrey;
}
.comments-area .comment-list li .comment-body .comment-info .comment-footer .comment-meta, .comments-area .comment-list li .comment-body .comment-info .comment-footer .reply {
  text-align:right;
  text-transform:initial;
  font-size:14px;
  font-style:italic;
  display:inline-block;
  vertical-align:top;
}
.comments-area .comment-list li .comment-body .comment-info .comment-footer .comment-meta a, .comments-area .comment-list li .comment-body .comment-info .comment-footer .reply a { color:$c-green; }
.comments-area .comment-list li .comment-body .comment-info .comment-footer .reply { margin-left:5px; }
.comments-area .comment-list li .comment-body .comment-info .comment-footer .reply a {
  color:$c-darkGrey;
  font-size:30px;
}
.comments-area .comment-list li .comment-body .comment-info .comment-footer .reply a:hover { color:$c-green; }
.comments-area .comment-list li .comment-body .comment-info:before {
  content:"";
  width:1px;
  height:97px;
  background:$c-lightishGrey;
  position:absolute;
  top:0;
  left:100px;
}
@media screen and (max-width: 767px) {
  .comments-area .comment-list li .comment-body .comment-avatar { position:static; }
  .comments-area .comment-list li .comment-body .comment-info { padding-left:0px; }
  .comments-area .comment-list li .comment-body .comment-info:before { display:none; }
  .comments-area .comment-list li ol.children { padding-left:20px; }
}
.comments-area .comment-respond .comment-reply-title {
  text-transform:uppercase;
  font-size:24px;
  line-height:41px;
  color:$c-darkBlueGrey;
  margin-bottom:0;
}
.comments-area .comment-respond .comment-reply-title span {
  font-weight:300;
  font-size:24px;
  text-transform:capitalize;
  color:#232530;
}
@media screen and (max-width: 767px) {
  .comments-area {
    margin-top:0px;
    padding-top:0px;
  }
}
.comment-form {
  margin:5px 0 0;
  margin-bottom:35px;
}
.comment-form p {
  line-height:normal;
  margin-bottom:20px;
}
.comment-form p.comment-form-author, .comment-form p.comment-form-email, .comment-form p.comment-form-url, .comment-form p.comment-form-comment {
  width:100%;
  display:inline-block;
  margin-top:0;
  position:relative;
  margin-bottom:0 !important;
}
.comment-form p input, .comment-form p textarea {
  margin-bottom:0;
  border:none;
  border-bottom:1px solid $c-lightishGrey;
  padding-left:0px;
  line-height:58px;
  padding-top:0px;
  padding-bottom:0px;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* IE 10+ */
  /* Firefox 18- */
}
.comment-form p input:hover, .comment-form p input:focus, .comment-form p textarea:hover, .comment-form p textarea:focus { border-color:$c-black; }
.comment-form p input::-webkit-input-placeholder, .comment-form p textarea::-webkit-input-placeholder { color:$c-darkBlueGrey; }
.comment-form p input::-moz-placeholder, .comment-form p textarea::-moz-placeholder { color:$c-darkBlueGrey; }
.comment-form p input:-ms-input-placeholder, .comment-form p textarea:-ms-input-placeholder { color:$c-darkBlueGrey; }
.comment-form p input:-moz-placeholder, .comment-form p textarea:-moz-placeholder { color:$c-darkBlueGrey; }
.comment-form p #comment { min-height:100px; }
.comment-form p label {
  @extend %sansSerif;
  font-weight:400;
  font-size:14px;
  line-height:60px;
  color:$c-darkBlueGrey;
  text-transform:capitalize;
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  left:0;
}
.comment-form p label span { display:none; }
.comment-form p.form-submit {
  margin:0;
  display:inline-block;
  vertical-align:middle;
  margin-top:30px;
  overflow:hidden;
  position:relative;
}
.comment-form p.form-submit .submit {
  text-transform:uppercase;
  @extend %sansSerif;
  font-weight:500;
  font-size:12px;
  letter-spacing:1px;
  border:1px solid $c-green;
  background:$c-green;
  line-height:21px;
  padding:8px 40px;
  color:$c-white;
}
