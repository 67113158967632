.woocommerce .ro-quantity {
  display:inline-block;
  vertical-align:middle;
}
.woocommerce .ro-quantity label {
  display:none;
  font-weight:400;
  float:left;
  width:15%;
  line-height:35px;
}
.woocommerce .ro-quantity label span { color:$c-orange; }
.woocommerce .ro-quantity label p { margin-bottom:10px !important; }
.woocommerce .ro-quantity .quantity {
  width:132px;
  margin:0 !important;
  border:1px solid $c-lightishGrey;
}
.woocommerce .ro-quantity .quantity span {
  float:left;
  width:25%;
  height:38px;
  line-height:38px;
  text-align:center;
  cursor:pointer;
  color:#969696;
}
.woocommerce .ro-quantity .quantity span:hover i { color:$c-orange; }
.woocommerce .ro-quantity .quantity span.qty-minus i {
  font-size:14px;
  color:#232530;
}
.woocommerce .ro-quantity .quantity span.qty-minus i:before { content:"\f104"; }
.woocommerce .ro-quantity .quantity span.qty-plus i {
  font-size:14px;
  color:#232530;
}
.woocommerce .ro-quantity .quantity span.qty-plus i:before { content:"\f105"; }
.woocommerce .ro-quantity .quantity input {
  float:left;
  width:50%;
  height:38px;
  margin-bottom:0;
  @extend %sansSerif;
  font-size:14px;
  color:#232530;
  border:none;
  -moz-appearance:textfield;
  -webkit-appearance:textfield;
  padding:0;
}
.woocommerce .ro-quantity .quantity input[type=number]::-webkit-inner-spin-button, .woocommerce .ro-quantity .quantity input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance:none;
  margin:0;
}
.woocommerce .yith-wcqv-main .ro-quantity .quantity input { width:100%; }
.addthis_inline_share_toolbox {
  display:inline-block;
  vertical-align:middle;
}
.addthis_inline_share_toolbox .at-share-btn-elements > a { margin:0 5px 5px; }
.addthis_inline_share_toolbox .at-share-btn-elements > a .at-label { display:none; }
.addthis_inline_share_toolbox .at-share-btn-elements .at-share-btn { background:none !important; }
.addthis_inline_share_toolbox .at-share-btn-elements .at-share-btn .at-icon { fill:#b3b7c8 !important; }
.addthis_inline_share_toolbox .at-share-btn-elements .at-share-btn .at-icon:hover { fill:$c-orange; }
.single-product { margin:50px 0; }
.single-product > .addthis-smartlayers { display:none; }
.single-product .ro-title h4 {
  margin-top:50px;
  margin-bottom:50px;
}
.single-product .nav-product-link a {
  height:21px;
  display:inline-block;
  width:21px;
  border:1px solid $c-lightishGrey;
  text-align:center;
  line-height:17px;
  border-radius:50%;
  font-size:14px;
  color:#717171;
}
.single-product .nav-product-link a:hover {
  background-color:$c-orange;
  border-color:$c-orange;
  color:$c-white;
}
.single-product div.product .ro-summary-wrapper {
  padding-left:0px;
  padding-right:0px;
}
.single-product div.product .ro-summary-wrapper span.onsale {
  width:30px;
  height:30px;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:0px;
  background:$c-orange;
}
.single-product div.product .ro-summary-wrapper span.productOwned {
  width: 90px;
  height: 90px;
  line-height: 1em;
  padding: 12px;
}
.single-product div.product .ro-summary-wrapper .images { width:100%; }
.single-product div.product .ro-summary-wrapper .images img {
  border:1px solid #000;
}
.single-product div.product .ro-summary-wrapper .images .thumbnails .caroufredsel_wrapper li a img {
  width:auto;
  display:block;
}
.single-product div.product .ro-summary-wrapper .images .thumbnails #slider-prev, .single-product div.product .ro-summary-wrapper .images .thumbnails #slider-next {
  width:22px;
  height:22px;
  background:none;
}
.single-product div.product .ro-summary-wrapper .images .thumbnails #slider-prev:before, .single-product div.product .ro-summary-wrapper .images .thumbnails #slider-next:before {
  border-radius:50%;
  border:1px solid #d9d9d9;
  width:100%;
  height:100%;
  content:"\F105";
  font-family:FontAwesome;
  text-align:center;
  line-height:22px;
  position:absolute;
  z-index:999;
}
.single-product div.product .ro-summary-wrapper .images .thumbnails #slider-prev:hover:before, .single-product div.product .ro-summary-wrapper .images .thumbnails #slider-next:hover:before {
  color:$c-white;
  background-color:$c-orange;
  border-color:$c-orange;
}
.single-product div.product .ro-summary-wrapper .images .thumbnails #slider-prev:before { content:"\F104"; }
.single-product div.product .ro-product-wrapper { padding-left:35px; }
body.bundle-product .single-product div.product .ro-product-wrapper { padding-left:0px; }
.single-product div.product .ro-product-wrapper .ro-product-information .ro-rate .star-rating {
  font-size:10px;
  margin-top:3px;
}
.single-product div.product .ro-product-wrapper .ro-product-information .ro-rate .woocommerce-review-link { display:none; }
.single-product div.product .ro-product-wrapper .tb-product-price .amount { font-size:16px; }
@media screen and (max-width: 767px) {
  .single-product div.product .ro-product-wrapper {
    padding-left:0px;
    padding-right:0px;
  }
}
.single-product .ro-product-wrapper .images { position:relative; }
.single-product .ro-product-wrapper .images .yith_magnifier_zoom_wrap .yith_magnifier_zoom_wrap .yith_magnifier_zoom_magnifier {
  left:0 !important;
  top:0 !important;
}
.single-product .ro-product-wrapper .images .woocommerce-main-image {
  max-height:440px;
  overflow:hidden;
}
.single-product .ro-product-wrapper .onsale, .single-product .ro-product-wrapper .new {
  position:absolute;
  left:auto;
  right:20px;
  width:48px;
  height:48px;
  line-height:43px;
  text-align:center;
  z-index:10;
}
.single-product .ro-product-wrapper .new { line-height:46px; }
.single-product .ro-product-wrapper .onsale, .single-product .ro-product-wrapper .new {
  top:0;
  font-weight:700;
  text-transform:uppercase;
  font-size:12px;
  background:transparent;
  color:$c-darkRedGrey;
}
.single-product .ro-product-wrapper .onsale:before, .single-product .ro-product-wrapper .new:before {
  position:absolute;
  content:"";
  border-bottom:66px solid transparent;
  top:3px;
  z-index:-1;
}
.single-product .ro-product-wrapper .onsale {
  left:0;
  -webkit-transform:rotate(-45deg);
  transform:rotate(-45deg);
}
.single-product .ro-product-wrapper .onsale:before {
  border-left:66px solid $c-white;
  left:-9px;
  -webkit-transform:rotate(45deg);
  transform:rotate(45deg);
}
.single-product .ro-product-wrapper .new {
  right:0;
  -webkit-transform:rotate(45deg);
  transform:rotate(45deg);
}
.single-product .ro-product-wrapper .new:before {
  border-right:66px solid $c-white;
  right:-9px;
  -webkit-transform:rotate(-45deg);
  transform:rotate(-45deg);
}
.single-product .ro-product-wrapper .ro-product-information .woocommerce-variation-description { display:none !important; }
.single-product .ro-product-wrapper .ro-product-information .entry-title {
  @extend %sansSerif;
  font-weight:400;
  font-size:24px;
  line-height:30px;
  text-transform:capitalize;
  color:#232530;
}
.single-product .ro-product-wrapper .ro-product-information .entry-title { margin-bottom:8px; }
.single-product .ro-product-wrapper .ro-product-information .entry-title .nav-product-link {
  height:0;
  margin-top:-27px;
  margin-bottom:30px;
  display:none;
}
.single-product .ro-product-wrapper .ro-product-information .entry-title .nav-product-link a { margin-left:8px; }
.single-product .ro-product-wrapper .ro-product-information .ro-product-price-meta {
  display:inline-block;
  width:100%;
}
.single-product .ro-product-wrapper .ro-product-information .ro-product-price-meta .tb-product-price {
  display:inline-block;
  float:left;
  width:100%;
}
.single-product .ro-product-wrapper .ro-product-information .ro-product-price-meta .tb-product-price .price {
  font-size:22px;
  font-weight:normal;
  margin-bottom:0;
}
.single-product .ro-product-wrapper .ro-product-information .ro-product-price-meta .tb-product-price .price del {
  color:$c-black;
  margin-left:10px;
  /*float:right;*/
  font-size:15px;
  line-height:38px;
  vertical-align:middle;

  span {
    color: $c-darkBlueGrey;
    font-size:15px;
  }
}
.single-product .ro-product-wrapper .ro-product-information .ro-product-price-meta .tb-product-price .price ins {
  font-size:16px;
  line-height:36px;
  color:$c-orange;
  text-decoration:none;
}
.single-product .ro-product-wrapper .ro-product-information .ro-product-price-meta .stock {
  display:inline-block;
  float:right;
  margin-top:9px;
  color:#898989;
  text-transform:capitalize;
}
.single-product .ro-product-wrapper .ro-product-information .ro-product-price-meta .stock span { color:$c-orange; }
.single-product .ro-product-wrapper .ro-product-information .ro-product-content {
  margin:0 0 25px;
  @extend %sansSerif;
  font-size:14px;
  line-height:26px;
  border-bottom:1px solid $c-lightishGrey;
  padding-bottom:17px;
}
.single-product .ro-product-wrapper .ro-product-information .ro-product-content .content {
  color:$c-darkBlueGrey;
  margin-bottom:8px !important;
}
.single-product .ro-product-wrapper .ro-product-information .ro-product-content ul {
  margin:0;
  padding:0;
  list-style:none;
}
.single-product .ro-product-wrapper .ro-product-information .ro-product-content ul li {
  color:$c-darkBlueGrey;
  line-height:30px;
}
.single-product .ro-product-wrapper .ro-product-information .ro-product-content ul li i {
  font-size:13px;
  margin-right:10px;
  color:$c-orange;
}
.single-product .ro-product-wrapper .ro-product-information > form { margin-bottom:20px !important; }
.single-product .ro-product-wrapper .ro-product-information > form .variations label {
  @extend %sansSerif;
  font-size:14px;
  line-height:24px;
  color:#232530;
  font-weight:400;
}
.single-product .ro-product-wrapper .ro-product-information .ro-rate {
  display:inline-block;
  width:100%;
}
.single-product .ro-product-wrapper .ro-product-information .ro-rate .woocommerce-product-rating { margin-bottom:5px; }
.single-product .ro-product-wrapper .ro-product-information .ro-rate .star-rating {
  font-size:12px;
  margin-top:7px;
}
.single-product .ro-product-wrapper .ro-product-information .ro-rate .star-rating span:before { color:#f5c136; }
.single-product .ro-product-wrapper .ro-product-information .ro-rate .woocommerce-review-link {
  color:$c-darkBlueGrey;
  font-size:12px;
}
.single-product .ro-product-wrapper .ro-product-information .ro-rate .woocommerce-review-link:hover { color:$c-orange; }
.single-product .ro-product-wrapper .ro-product-information .sku-cat-tag { margin-bottom:13px; }
.single-product .ro-product-wrapper .ro-product-information .sku-cat-tag .get-sku, .single-product .ro-product-wrapper .ro-product-information .sku-cat-tag .get-cat, .single-product .ro-product-wrapper .ro-product-information .sku-cat-tag .get-tag {
  display:inline-block;
  vertical-align:middle;
  @extend %sansSerif;
  font-weight:400;
  font-size:14px;
  line-height:40px;
  text-transform:capitalize;
  color:$c-darkBlueGrey;
  position:relative;
  padding-right:12.5px;
  margin-right:12.5px;
}
.single-product .ro-product-wrapper .ro-product-information .sku-cat-tag .get-sku span, .single-product .ro-product-wrapper .ro-product-information .sku-cat-tag .get-cat span, .single-product .ro-product-wrapper .ro-product-information .sku-cat-tag .get-tag span {
  color:#232530;
  font-weight:500;
}
.single-product .ro-product-wrapper .ro-product-information .sku-cat-tag .get-sku:before, .single-product .ro-product-wrapper .ro-product-information .sku-cat-tag .get-cat:before, .single-product .ro-product-wrapper .ro-product-information .sku-cat-tag .get-tag:before {
  content:"";
  width:1px;
  height:13px;
  background:#b3b7c8;
  position:absolute;
  top:50%;
  right:-2px;
  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  -o-transform:translateY(-50%);
}
.single-product .ro-product-wrapper .ro-product-information .sku-cat-tag .get-tag a, .single-product .ro-product-wrapper .ro-product-information .sku-cat-tag .get-cat a {
  font-weight:400;
  color:$c-orange;
}
.single-product .ro-product-wrapper .ro-product-information .sku-cat-tag .get-tag {
  padding-right:0;
  margin-right:0;
}
.single-product .ro-product-wrapper .ro-product-information .sku-cat-tag .get-tag:before { display:none; }
.single-product .ro-product-wrapper .ro-product-information .sku-cat-tag >div:last-child:before { display:none; }
.single-product .ro-product-wrapper .ro-product-information .share-all {
  @extend %sansSerif;
  font-weight:400;
  font-size:14px;
  line-height:24px;
  text-transform:capitalize;
  color:#232530;
}
.single-product .ro-product-wrapper .ro-product-information .ro-quantity > div, .single-product .ro-product-wrapper .ro-product-information .ro-price > div { display:inline-block; }
.single-product .ro-product-wrapper .ro-product-information .ro-quantity > div:nth-child(1), .single-product .ro-product-wrapper .ro-product-information .ro-price > div:nth-child(1) {
  width:15%;
  float:left;
  padding-top:2px;
}
.single-product .ro-product-wrapper .ro-product-information .ro-quantity > div:nth-child(1) p, .single-product .ro-product-wrapper .ro-product-information .ro-price > div:nth-child(1) p { margin-bottom:13px; }
.single-product .ro-product-wrapper .ro-product-information .single_add_to_cart_button {
  padding:0 25px 0 25px;
  background-color:$c-orange;
  border-color:$c-orange;
  font-weight:500;
  font-size:13px;
  line-height:41px;
  margin-right:0;
  margin-left:6px;
  color:$c-white;
}
.single-product .ro-product-wrapper .ro-product-information .single_add_to_cart_button:before { display:none; }
.single-product .ro-product-wrapper .ro-product-information .single_add_to_cart_button:before {
  color:$c-white;
  margin:2px 0 0 -10px;
}
.single-product .ro-product-wrapper .ro-product-information .single_add_to_cart_button:hover {
  background-color:$c-orange;
  border-color:$c-orange;
}
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-quantity:after {
  content:"";
  display:block;
  width:100%;
  clear:both;
}
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action {
  display:inline-block;
  vertical-align:middle;
}
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action > div.clear { display:none; }
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist {
  display:none;
  -webkit-transition:all 0.4s ease 0s;
  -moz-transition:all 0.4s ease 0s;
  -ms-transition:all 0.4s ease 0s;
  -o-transition:all 0.4s ease 0s;
  position:relative;
  vertical-align:middle;
  padding:0;
  overflow:hidden;
}
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist .add_to_wishlist {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  opacity:0;
}
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist .yith-wcwl-add-button:before {
  -webkit-transition:all 0.4s ease 0s;
  -moz-transition:all 0.4s ease 0s;
  -ms-transition:all 0.4s ease 0s;
  -o-transition:all 0.4s ease 0s;
  font-family:FontAwesome;
  content:"\f004";
  position:absolute;
  top:50%;
  font-size:15px;
  font-weight:400;
  width:100%;
  text-align:center;
  left:50%;
  -webkit-transform:translateX(-50%) translateY(-50%);
  -moz-transform:translateX(-50%) translateY(-50%);
  -o-transform:translateX(-50%) translateY(-50%);
  -ms-transform:translateX(-50%) translateY(-50%);
  transform:translateX(-50%) translateY(-50%);
}
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist .yith-wcwl-add-button:hover:before { color:$c-white; }
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse > span, .single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse.show > span, .single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse > span, .single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse.show > span { opacity:0; }
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse > a, .single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse.show > a, .single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse > a, .single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse.show > a {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  opacity:0;
}
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse:before, .single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse.show:before, .single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse:before, .single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse.show:before {
  -webkit-transition:all 0.4s ease 0s;
  -moz-transition:all 0.4s ease 0s;
  -ms-transition:all 0.4s ease 0s;
  -o-transition:all 0.4s ease 0s;
  font-family:FontAwesome;
  content:"\f004";
  font-weight:400;
  position:absolute;
  top:50%;
  left:0;
  width:100%;
  text-align:center;
  font-size:15px;
  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  -o-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  transform:translateY(-50%);
}
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist:hover {
  background:rgba(0,0,0,0.8);
  border-color:rgba(0,0,0,0.8);
}
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist:hover .yith-wcwl-wishlistaddedbrowse:before, .single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist:hover .yith-wcwl-wishlistaddedbrowse.show:before, .single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist:hover .yith-wcwl-wishlistexistsbrowse:before, .single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .yith-wcwl-add-to-wishlist:hover .yith-wcwl-wishlistexistsbrowse.show:before { color:$c-white; }
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .compare-button {
  display:none;
  -webkit-transition:all 0.4s ease 0s;
  -moz-transition:all 0.4s ease 0s;
  -ms-transition:all 0.4s ease 0s;
  -o-transition:all 0.4s ease 0s;
  position:relative;
  vertical-align:middle;
  width:40px;
  height:40px;
  padding:0;
}
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .compare-button .compare {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  opacity:0;
}
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .compare-button:before {
  -webkit-transition:all 0.4s ease 0s;
  -moz-transition:all 0.4s ease 0s;
  -ms-transition:all 0.4s ease 0s;
  -o-transition:all 0.4s ease 0s;
  font-family:FontAwesome;
  content:"\f0ec";
  position:absolute;
  font-weight:400;
  top:50%;
  font-size:15px;
  left:50%;
  -webkit-transform:translate(-50%,-50%);
  -moz-transform:translate(-50%,-50%);
  -ms-transform:translate(-50%,-50%);
  -o-transform:translate(-50%,-50%);
}
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .compare-button:hover {
  background:rgba(0,0,0,0.8);
  border-color:rgba(0,0,0,0.8);
}
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap .ro-action .compare-button:hover:before { color:$c-white; }
.single-product .ro-product-wrapper .ro-product-information .single_simple_wrap form.cart { margin-bottom:20px; }
.single-product .ro-product-wrapper .ro-product-information .single_variation_wrap {
  border-bottom:1px solid $c-lightishGrey;
  padding-bottom:30px;
}
.single-product .ro-product-wrapper .ro-product-information .single_variation_wrap .woocommerce-variation-description p { margin-bottom:5px; }
.single-product .ro-product-wrapper .ro-product-information .single_variation_wrap .ro-price .single_variation .price {
  font-size:13px;
  color:#515151;
  font-weight:700;
  line-height:25px;
}
.single-product .ro-product-wrapper .ro-product-information .single_variation_wrap .ro-price p { color:#515151; }
.single-product .ro-product-wrapper .ro-product-information .single_variation_wrap .ro-price del {
  display:inline-block;
  margin-right:20px;
}
.single-product .ro-product-wrapper .ro-product-information .single_variation_wrap label {
  font-size:13px;
  color:#515151;
  font-weight:normal;
}
.single-product .ro-product-wrapper .ro-product-information .single_variation_wrap .variations_button {
  display:inline-block;
  vertical-align:middle;
}
.single-product .ro-product-wrapper .ro-product-information .single_variation_wrap .variations_button .single_add_to_cart_button {
  padding:0 25px 0 25px;
  background-color:$c-orange;
  border-color:$c-orange;
  font-weight:500;
  font-size:13px;
  line-height:41px;
  margin-right:0;
  margin-left:6px;
}
.single-product .ro-product-wrapper .ro-product-information .single_variation_wrap .variations_button .single_add_to_cart_button:before { display:none; }
.single-product .ro-product-wrapper .ro-product-information .single_variation_wrap .variations_button > div.clear { display:none; }
.single-product .ro-product-wrapper .ro-product-information .single_variation_wrap .variations_button .yith-wcwl-add-to-wishlist { display:none; }
.single-product .ro-product-wrapper .ro-product-information .single_variation_wrap .variations_button .compare-button { display:none; }
.single-product .ro-product-wrapper .ro-product-information .product_meta span {
  display:inline-block;
  margin-right:10px;
}
.single-product .ro-product-wrapper .ro-product-information .product_meta span:last-child { margin:0; }
.single-product .ro-product-wrapper .ro-product-information .product_meta span a { color:$c-darkGrey; }
.single-product .ro-product-wrapper .ro-product-information .product_meta span a:hover { color:$c-orange; }
.single-product .ro-product-wrapper .ro-product-information .ro-footer h6 {
  margin-top:25px;
  margin-bottom:20px;
}
.single-product .show_or_hide { display:block; }
.single-product .ro-product-relate { margin:40px 0 27px; }
.single-product .ro-product-relate .ro-title {
  position:relative;
  margin:20px 0 18px;
}
.single-product .ro-product-relate .ro-title:before {
  background:#efefef;
  content:"";
  height:1px;
  left:0;
  position:absolute;
  top:16px;
  width:100%;
  z-index:0;
}
.single-product .ro-product-relate .ro-title h4 {
  background-color:$c-white;
  color:#272727;
  display:inline-block;
  font-size:24px;
  font-weight:300;
  margin:0;
  padding:5px 0px 5px;
  z-index:1;
  position:relative;
  padding-right:25px;
}
.single-product .ro-product-relate .owl-controls { top:-48px; }
.single-product .tb-sidebar .upsells .wg-title { margin-bottom:26px; }
.single-product .addthis_sharing_toolbox { margin-top:55px; }
.single-product .tb-content.tb-fullwidth .ro-product-wrapper .images {
  overflow:hidden;
  max-height:500px;
}
.single-product .tb-content.tb-fullwidth .ro-product-wrapper .images .yith_magnifier_zoom_wrap .yith_magnifier_zoom_wrap .yith_magnifier_zoom_magnifier {
  left:0 !important;
  top:0 !important;
}
.single-product .tb-content.tb-fullwidth .ro-product-wrapper.vertical-slider .onsale { left:120px; }
.single-product .tb-content.tb-fullwidth .ro-product-wrapper.vertical-slider .tb-single-vertical {
  position:absolute;
  left:-120px;
  top:32px;
  visibility:visible;
}
.single-product .tb-content.tb-fullwidth .ro-product-wrapper.vertical-slider .images { overflow:visible; }
.single-product .tb-content.tb-fullwidth .ro-product-wrapper.vertical-slider #prev-product {
  position:absolute;
  top:-32px;
}
.single-product .tb-content.tb-fullwidth .ro-product-wrapper.vertical-slider #next-product { margin-top:7px; }
.single-product .tb-content.tb-fullwidth .woocommerce-product-rating { margin-top:-2px; }
.single-product .tb-content.tb-fullwidth .addthis_sharing_toolbox {
  text-align:right;
  margin-top:-40px;
}
.single-product .tb-content.tb-fullwidth .tb-single-vertical { visibility:hidden; }
.single-product .tb-content.tb-fullwidth .tb-single-vertical .slideshow img {
  margin:16px 0;
  width:100px;
}
.single-product .tb-content.tb-fullwidth .woocommerce-tabs #tab-video iframe { width:100%; }
.single-product .tb-content.tb-fullwidth .upsellss { margin-bottom:30px; }
.single-product .tb-content.tb-fullwidth .upsellss .deersmart-title {
  margin-bottom:15px;
  text-transform:uppercase;
}
@media (min-width: 768px) {
  .single-product .ro-product-wrapper .ro-product-information {
    margin-right:-15px;
    padding-left:4px;
  }
}
@media (max-width: 767px) {
  .single-product .ro-product-wrapper { padding-left:0; }
}
.woocommerce-cart table, .woocommerce-cart th, .woocommerce-cart td { border:1px solid $c-lightGrey; }
.woocommerce-cart .woocommerce table.shop_table {
  border-collapse:collapse;
  margin-bottom:50px;
}
.woocommerce-cart .woocommerce table.shop_table tr th {
  font-size:18px;
  color:#232530;
  font-weight:700;
  text-align:center;
  border-right:0px;
  border-left:0px;
}
.woocommerce-cart .woocommerce table.shop_table tr th.product-thumbnail {
  text-align:left;
  padding-left:30px;
}
.woocommerce-cart .woocommerce table tr th.product-name { text-align:left; width:50%; }
.woocommerce-cart .woocommerce table.shop_table tr:last-child td {
  border:1px solid transparent;
  padding:0px;
  padding-top:30px;
}
.woocommerce-cart .woocommerce table.shop_table tr:last-child td .return-to-shop { margin:0px !important; }
.woocommerce-cart .woocommerce table.shop_table tr:last-child td .return-to-shop a {
  color:$c-white;
  background:#232530;
  line-height:40px;
  font-size:12px;
  text-transform:uppercase;
  padding:0px;
  padding-left:30px;
  padding-right:30px;
  font-weight:500;
  -webkit-border-radius:2px;
  -moz-border-radius:2px;
  border-radius:2px;
}
.woocommerce-cart .woocommerce table.shop_table tr:last-child td .return-to-shop a:hover { background:$c-orange; }
.woocommerce-cart .woocommerce table.shop_table tr:last-child td.actions { vertical-align:top; }
.woocommerce-cart .woocommerce table.shop_table tr:last-child td.actions input[type="submit"] {
  line-height:40px;
  font-size:12px;
  text-transform:uppercase;
  color:$c-white;
  background:#232530;
  opacity:1;
  padding:0px;
  display:inline-block;
  font-weight:500;
  width:auto;
  padding-left:30px;
  padding-right:30px;
  -webkit-border-radius:2px;
  -moz-border-radius:2px;
  border-radius:2px;
}
.woocommerce-cart .woocommerce table.shop_table tr:last-child td.actions input[type="submit"]:hover { background:$c-orange; }
.woocommerce-cart .woocommerce table.shop_table tr td.btn-return-to-shop:before, .woocommerce-cart .woocommerce table.shop_table tr td.actions:before { display:none; }
.woocommerce-cart .woocommerce table.shop_table tr.ro-cart-item td {
  text-align:center;
  font-size:16px;
  color:#232530;
  font-weight:300;
}
.woocommerce-cart .woocommerce table.shop_table tr.ro-cart-item td.product-thumbnail {
  text-align:left;
  padding:30px 30px 30px 30px;
}
.woocommerce-cart .woocommerce table.shop_table tr.ro-cart-item td.product-thumbnail img {
  width:100%;
  max-width:170px;
}
.woocommerce-cart .woocommerce table.shop_table tr.ro-cart-item td.product-name { text-align:left; }
.woocommerce-cart .woocommerce table.shop_table tr.ro-cart-item td.product-subtotal span, .woocommerce-cart .woocommerce table.shop_table tr.ro-cart-item td.product-price span { color:$c-orange; }
.woocommerce-cart .woocommerce table.shop_table tr.ro-cart-item td.product-quantity .ro-quantity .quantity { border:0px; }
.woocommerce-cart .woocommerce table.shop_table tr.ro-cart-item td.product-quantity .ro-quantity .quantity input { font-size:16px; }
.woocommerce-cart .woocommerce table.shop_table tr.ro-cart-item td.product-quantity .ro-quantity .quantity i { font-size:16px; }
.woocommerce-cart .woocommerce table.shop_table tr.ro-cart-item td.product-remove a {
  border:1px solid rgba(54,54,54,0.1);
  color:$c-black !important;
  border-radius:0px;
}
.woocommerce-cart .woocommerce table.shop_table tr.ro-cart-item:first-child td { /*padding-top:30px;*/ }
@media screen and (max-width: 767px) {
  .woocommerce-cart .woocommerce table.shop_table tr.ro-cart-item:first-child td { padding-top:6px; }
  .woocommerce-cart .woocommerce table.shop_table tr.ro-cart-item td:before { font-weight:300; }
}
.woocommerce-cart .woocommerce .ro-cart-below { margin-bottom:50px; }
.woocommerce-cart .woocommerce .ro-cart-below .box-cart { border:1px solid $c-lightGrey; }
.woocommerce-cart .woocommerce .ro-cart-below .box-cart h3.title {
  border-bottom:1px solid $c-lightGrey;
  font-size:18px;
  color:#232530;
  font-weight:300;
  text-align:center;
  padding:17px 10px;
  margin-bottom:0px;
}
.woocommerce-cart .woocommerce .ro-cart-below .box-cart .box-body { padding:20px; }
.woocommerce-cart .woocommerce .ro-cart-below .box-cart .box-body form.checkout_coupon {
  padding:0px;
  margin:0px;
  text-align:center;
  border:0px;
}
.woocommerce-cart .woocommerce .ro-cart-below .box-cart .box-body form.checkout_coupon label { margin-bottom:22px; }
.woocommerce-cart .woocommerce .ro-cart-below .box-cart .box-body form.checkout_coupon input[type="text"] {
  width:calc(100% - 150px);
  display:inline-block;
  border-top:1px solid transparent;
  border-left:1px solid transparent;
  border-right:16px solid transparent;
}
.woocommerce-cart .woocommerce .ro-cart-below .box-cart .box-body form.checkout_coupon input[type="submit"] {
  padding:0px;
  line-height:40px;
  padding-left:30px;
  padding-right:30px;
  background:#232530;
  color:$c-white;
  font-size:12px;
  text-transform:uppercase;
  -webkit-border-radius:2px;
  -moz-border-radius:2px;
  border-radius:2px;
  font-weight:500;
  margin-top:0px;
  display:inline-block;
  width:auto;
}
.woocommerce-cart .woocommerce .ro-cart-below .box-cart .box-body form.checkout_coupon input[type="submit"]:hover { background:$c-orange; }
.woocommerce-cart .woocommerce .ro-cart-below .box-cart .box-body ul#shipping_method li { display:inline-block; }
.woocommerce-cart .woocommerce .ro-cart-below .box-cart .box-body ul#shipping_method li input { margin:0px; }
.woocommerce-cart .woocommerce .ro-cart-below .cart-collaterals .cart_totals { width:inherit; }
.woocommerce-cart .woocommerce .ro-cart-below .cart-collaterals .cart_totals h2 {
  font-size:24px;
  text-transform:uppercase;
}
.woocommerce-cart .woocommerce .ro-cart-below .cart-collaterals .cart_totals table {
  border:0px;
  padding-left:22px;
  padding-right:22px;
  color:$c-darkBlueGrey;
}
.woocommerce-cart .woocommerce .ro-cart-below .cart-collaterals .cart_totals table th, .woocommerce-cart .woocommerce .ro-cart-below .cart-collaterals .cart_totals table td { border:0px; }
.woocommerce-cart .woocommerce .ro-cart-below .cart-collaterals .cart_totals table th {
  text-align:left;
  font-size:14px;
  text-transform:uppercase;
  font-weight:400;
  width:40%;
}
.woocommerce-cart .woocommerce .ro-cart-below .cart-collaterals .cart_totals table td { text-align:right; }
.woocommerce-cart .woocommerce .ro-cart-below .cart-collaterals .cart_totals table td span, .woocommerce-cart .woocommerce .ro-cart-below .cart-collaterals .cart_totals table td strong {
  color:$c-orange;
  font-weight:400;
  font-size:16px;
}
.woocommerce-cart .woocommerce .ro-cart-below .cart-collaterals .cart_totals .return-to-shop { float:left; }
.woocommerce-cart .woocommerce .ro-cart-below .cart-collaterals .cart_totals .checkout-button {
  max-width:290px;
  width:100%;
  display:block;
  margin:0px auto;
  -moz-border-radius:2px;
  -webkit-border-radius:2px;
  /* border radius */
  border-radius:2px;
  -moz-background-clip:padding;
  -webkit-background-clip:padding-box;
  /* prevents bg color from leaking outside the border */
  background-clip:padding-box;
  /* layer fill content */
  background-color:#232530;
  font-size:12px;
  text-transform:uppercase;
  font-weight:500;
  padding:13px;
}
.woocommerce-cart .woocommerce .ro-cart-below .cart-collaterals .cart_totals .checkout-button:hover {
  background-color:$c-orange;
  border-color:$c-orange;
  color:$c-white;
}
.woocommerce-cart .woocommerce .woocommerce-shipping-calculator .tb-form-calculator-title {
  margin-bottom:10px;
  color:#555;
}
.woocommerce-cart .woocommerce .woocommerce-shipping-calculator .form-shipping-calculator button[type="submit"] {
  -moz-border-radius:2px;
  -webkit-border-radius:2px;
  /* border radius */
  border-radius:2px;
  -moz-background-clip:padding;
  -webkit-background-clip:padding-box;
  /* prevents bg color from leaking outside the border */
  background-clip:padding-box;
  /* layer fill content */
  background-color:#232530;
  color:$c-white;
  font-size:12px;
  font-weight:500;
  text-transform:uppercase;
  margin:0px auto;
  padding:13px 34px;
  display:block;
  margin-top:15px;
}
.woocommerce-cart .woocommerce .woocommerce-shipping-calculator .form-shipping-calculator button[type="submit"]:hover { background:$c-orange; }
.woocommerce-cart .woocommerce .woocommerce-shipping-calculator .form-shipping-calculator p {
  display:inline-block;
  width:100%;
  margin-bottom:5px !important;
}
.woocommerce-cart .woocommerce .woocommerce-shipping-calculator .form-shipping-calculator p label {
  width:150px;
  float:left;
  color:$c-darkBlueGrey;
  font-size:14px;
  text-transform:uppercase;
  font-weight:normal;
}
.woocommerce-cart .woocommerce .woocommerce-shipping-calculator .form-shipping-calculator p label .required { color:$c-darkBlueGrey; }
.woocommerce-cart .woocommerce .woocommerce-shipping-calculator .form-shipping-calculator p input, .woocommerce-cart .woocommerce .woocommerce-shipping-calculator .form-shipping-calculator p select {
  float:left;
  width:calc( 100% - 150px ) !important;
  min-width:110px;
}
@media screen and (max-width: 767px) {
  .woocommerce-cart .woocommerce .woocommerce-shipping-calculator .form-shipping-calculator p input, .woocommerce-cart .woocommerce .woocommerce-shipping-calculator .form-shipping-calculator p select { width:100% !important; }
}
.ro-checkout-process {
  margin-top:85px;
  margin-bottom:140px;
  padding-left:40px;
  padding-right:40px;
}
.ro-hr-line,
.ro-checkout-process .ro-hr-line {
  width:100%;
  border-top:1px solid #AAAAAA;
  position:relative;
}
.ro-checkout-process .ro-hr-line a div {
  -webkit-transition:all 0.4s;
  -moz-transition:all 0.4s;
  -o-transition:all 0.4s;
  transition:all 0.4s;
  border-radius:50%;
  position:absolute;
  width:20px;
  height:20px;
  border:1px solid #AAAAAA;
  background-color:$c-white;
  transform:translate(-50%,-50%);
}
.ro-checkout-process .ro-hr-line a div:after {
  -webkit-transition:all 0.4s;
  -moz-transition:all 0.4s;
  -o-transition:all 0.4s;
  transition:all 0.4s;
  content:"";
  width:14px;
  height:14px;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  background-color:$c-orange;
  border-radius:50%;
  opacity:0;
}
.ro-checkout-process .ro-hr-line a:nth-of-type(1) div {
  left:0;
  border:1px solid $c-orange;
}
.ro-checkout-process .ro-hr-line a:nth-of-type(1) div:after { opacity:1; }
.ro-checkout-process .ro-hr-line a:nth-of-type(2) div { left:50%; }
.ro-checkout-process .ro-hr-line a:nth-of-type(2):hover div { border:1px solid $c-orange; }
.ro-checkout-process .ro-hr-line a:nth-of-type(2):hover div:after { opacity:1; }
.ro-checkout-process .ro-hr-line a:nth-of-type(3) div { left:100%; }
.ro-checkout-process .ro-hr-line a:nth-of-type(3):hover div { border:1px solid $c-orange; }
.ro-checkout-process .ro-hr-line a:nth-of-type(3):hover div:after { opacity:1; }
.ro-checkout-process .ro-hr-line span {
  position:absolute;
  top:20px;
  width:70px;
  left:0;
  transform:translate(-50%,0);
  text-align:center;
  font-size:18px;
}
.ro-checkout-process .ro-hr-line span:nth-of-type(1) {
  left:0;
  font-weight:700;
}
.ro-checkout-process .ro-hr-line span:nth-of-type(2) { left:50%; }
.ro-checkout-process .ro-hr-line span:nth-of-type(3) { left:100%; }
.ro-checkout-process.ro-process-2 .ro-hr-line a:nth-of-type(1) div { border:2px solid #AAAAAA; }
.ro-checkout-process.ro-process-2 .ro-hr-line a:nth-of-type(1) div:after {
  opacity:1;
  color:$c-orange;
}
.ro-checkout-process.ro-process-2 .ro-hr-line a:nth-of-type(2) div { border:1px solid $c-orange; }
.ro-checkout-process.ro-process-2 .ro-hr-line a:nth-of-type(2) div:after { opacity:1; }
.ro-checkout-process.ro-process-2 .ro-hr-line span:nth-of-type(2) { font-weight:700; }
.ro-checkout-process.ro-process-3 .ro-hr-line a:nth-of-type(1) div, .ro-checkout-process.ro-process-3 .ro-hr-line a:nth-of-type(2) div { border:2px solid #AAAAAA; }
.ro-checkout-process.ro-process-3 .ro-hr-line a:nth-of-type(1) div:after, .ro-checkout-process.ro-process-3 .ro-hr-line a:nth-of-type(2) div:after {
  opacity:1;
  font-family:"aqua icon";
  color:$c-orange;
}
.ro-checkout-process.ro-process-3 .ro-hr-line a:nth-of-type(3) div { border:1px solid $c-orange; }
.ro-checkout-process.ro-process-3 .ro-hr-line a:nth-of-type(3) div:after { opacity:1; }
.ro-checkout-process.ro-process-3 .ro-hr-line span:nth-of-type(2) { font-weight:700; }
form.woocommerce-checkout .col2-set, form.woocommerce-checkout .col2-set-1 { margin-bottom:30px !important; }
form.woocommerce-checkout .ro-panel-2 { display:none; }
form.woocommerce-checkout .ro-quantity { border-top:none; }
.woocommerce-checkout h2 {
  font-size:18px;
  text-transform:capitalize;
}
.woocommerce-checkout .addresses .title > h3 {
  font-size:14px;
  text-transform:capitalize;
}
.woocommerce-checkout table.shop_table { margin-bottom:40px !important; }
.woocommerce-checkout table.shop_table td { border-top:none; }
.ro-checkout-panel h4 { text-transform:capitalize; }
.ro-checkout-panel .text-center { text-align:left; }
.ro-checkout-information .ro-customer-info > p:first-of-type a {
  margin-left:25px;
  color:$c-orange;
  -webkit-transition:all 0.4s;
  -moz-transition:all 0.4s;
  -o-transition:all 0.4s;
  transition:all 0.4s;
}
.ro-checkout-information .ro-customer-info > p:first-of-type a:after {
  -webkit-transition:all 0.4s;
  -moz-transition:all 0.4s;
  -o-transition:all 0.4s;
  transition:all 0.4s;
  font-family:"aqua icon";
  content:"\e086";
  margin-left:10px;
  vertical-align:middle;
  color:$c-orange;
  font-size:12px;
}
.ro-checkout-information .ro-customer-info > p:first-of-type a:hover { color:#AAAAAA; }
.ro-checkout-information .ro-customer-info > p:first-of-type a:hover:after { color:#AAAAAA; }
.ro-checkout-information .ro-customer-info > p:nth-of-type(3) { margin-bottom:15px; }
.ro-checkout-information .ro-customer-info .ro-account input {
  margin-bottom:30px;
  border:1px solid #AAAAAA;
}
.ro-checkout-information .ro-customer-info .ro-account input:focus { border:1px solid $c-orange; }
.ro-checkout-information .ro-customer-info .ro-choice label {
  cursor:pointer;
  font-weight:600;
}
.ro-checkout-information .ro-customer-info .ro-choice label span i { font-weight:400; }
.ro-checkout-information .ro-customer-info .ro-choice label input[type="radio"] { display:none; }
.ro-checkout-information .ro-customer-info .ro-choice label input[type="radio"] + span:before {
  -webkit-transition:all 0.4s;
  -moz-transition:all 0.4s;
  -o-transition:all 0.4s;
  transition:all 0.4s;
  content:'';
  display:inline-block;
  width:10px;
  height:10px;
  vertical-align:middle;
  border-radius:50%;
  margin-right:10px;
  border:1px solid $c-white;
  box-shadow:0 0 0 1px $c-orange;
}
.ro-checkout-information .ro-customer-info .ro-choice label input[type="radio"]:checked + span:before { background-color:$c-orange; }
.ro-checkout-information .ro-customer-info .ro-divide {
  border-bottom:1px solid #CCCCCC;
  margin-top:40px;
}
.ro-checkout-information .ro-shipping {
  padding-top:55px;
  padding-bottom:85px;
}
.ro-checkout-information .ro-shipping h4 { margin-bottom:60px; }
.ro-checkout-information .ro-shipping .ro-content div { margin-bottom:20px; }
.ro-checkout-information .ro-shipping .ro-content div:before, .ro-checkout-information .ro-shipping .ro-content div:after {
  content:" ";
  display:table;
}
.ro-checkout-information .ro-shipping .ro-content div:after { clear:both; }
.ro-checkout-information .ro-shipping .ro-content div span { line-height:40px; }
.ro-checkout-information .ro-shipping .ro-content div input { border:1px solid #AAAAAA; }
.ro-checkout-information .ro-shipping .ro-content div input:focus { border:1px solid $c-orange; }
.ro-checkout-information .ro-shipping .ro-button {
  margin-top:60px;
  text-align:center;
}
.ro-checkout-information .ro-shipping .ro-button a {
  -webkit-transition:all 0.4s;
  -moz-transition:all 0.4s;
  -o-transition:all 0.4s;
  transition:all 0.4s;
  border:0;
  background-color:$c-darkGrey;
  color:$c-white;
  font-size:14px;
  padding:15px 48px;
}
.ro-checkout-information .ro-shipping .ro-button a:hover { background-color:$c-orange; }
@media (min-width: 768px) {
  .ro-checkout-information .ro-customer-info .ro-choice label { padding-left:25px; }
}
@media (min-width: 992px) {
  .ro-checkout-information .ro-customer-info .ro-account input { width:250px; }
  .ro-checkout-information .ro-customer-info .ro-account input:nth-of-type(2) { margin-left:30px; }
  .ro-checkout-information .ro-shipping .ro-content div span {
    display:inline-block;
    width:30%;
    float:left;
  }
  .ro-checkout-information .ro-shipping .ro-content div input {
    width:70%;
    display:inline-block;
  }
  .ro-checkout-information .ro-shipping .ro-button {
    padding-right:100px;
    text-align:right;
  }
}
@media (min-width: 1200px) {
  .ro-checkout-information .ro-customer-info .ro-account input { width:270px; }
  .ro-checkout-information .ro-customer-info .ro-divide { width:580px; }
  .ro-checkout-information .ro-shipping .ro-content div { padding-right:80px; }
  .ro-checkout-information .ro-shipping .ro-button {
    padding-right:200px;
    text-align:right;
  }
}
.ro-checkout-information-2 .ro-divide { border-bottom:1px solid #CCCCCC; }
.ro-checkout-information-2 .ro-customer-info .ro-title {
  position:relative;
  margin-bottom:40px;
}
.ro-checkout-information-2 .ro-customer-info .ro-title h4 { margin-bottom:0; }
.ro-checkout-information-2 .ro-customer-info .ro-title span {
  position:absolute;
  right:0;
  top:0;
}
.ro-checkout-information-2 .ro-customer-info .ro-title span a { color:$c-orange; }
.ro-checkout-information-2 .ro-customer-info .ro-title span a:hover { color:#AAAAAA; }
.ro-checkout-information-2 .ro-customer-info .ro-content .ro-info span {
  display:inline-block;
  min-width:160px;
}
.ro-checkout-information-2 .ro-shipping { padding-top:45px; }
.ro-checkout-information-2 .ro-shipping h4 { margin-bottom:40px; }
.ro-checkout-information-2 .ro-shipping .ro-content {
  background-color:#F5F4F1;
  padding:20px 30px;
}
.ro-checkout-information-2 .ro-shipping .ro-content:before, .ro-checkout-information-2 .ro-shipping .ro-content:after {
  content:" ";
  display:table;
}
.ro-checkout-information-2 .ro-shipping .ro-content:after { clear:both; }
.ro-checkout-information-2 .ro-shipping .ro-content p {
  margin-bottom:0;
  display:inline-block;
  width:85%;
}
.ro-checkout-information-2 .ro-shipping .ro-content span {
  width:15%;
  display:inline-block;
  clear:both;
  float:right;
  text-align:right;
}
.ro-checkout-information-2 .ro-shipping .ro-divide { margin-top:60px; }
.ro-checkout-information-2 .ro-bill label {
  cursor:pointer;
  font-weight:600;
}
.ro-checkout-information-2 .ro-bill label span { position:relative; }
.ro-checkout-information-2 .ro-bill label input[type="radio"] { display:none; }
.ro-checkout-information-2 .ro-bill label input[type="radio"] + span:before {
  content:'';
  display:inline-block;
  width:18px;
  height:18px;
  vertical-align:middle;
  border-radius:50%;
  margin-right:15px;
  box-shadow:0 0 0 1px #AAAAAA;
}
.ro-checkout-information-2 .ro-bill label input[type="radio"]:checked + span:before {
  box-shadow:0 0 0 1px $c-orange;
  background-color:$c-orange;
}
.ro-checkout-information-2 .ro-bill label input[type="radio"]:checked + span:after {
  content:"4";
  font-family:"aqua icon";
  position:absolute;
  top:-3px;
  left:1px;
  font-size:16px;
  color:$c-white;
}
.ro-checkout-information-2 .ro-bill .ro-credit-card:before, .ro-checkout-information-2 .ro-bill .ro-credit-card:after {
  content:" ";
  display:table;
}
.ro-checkout-information-2 .ro-bill .ro-credit-card:after { clear:both; }
.ro-checkout-information-2 .ro-bill .ro-credit-card .ro-head .ro-image {
  clear:both;
  float:right;
}
.ro-checkout-information-2 .ro-bill .ro-credit-card .ro-body {
  background-color:#F5F4F1;
  padding:20px 30px;
  margin-top:20px;
  margin-bottom:20px;
}
.ro-checkout-information-2 .ro-bill .ro-credit-card .ro-body .ro-info:before, .ro-checkout-information-2 .ro-bill .ro-credit-card .ro-body .ro-info:after {
  content:" ";
  display:table;
}
.ro-checkout-information-2 .ro-bill .ro-credit-card .ro-body .ro-info:after { clear:both; }
.ro-checkout-information-2 .ro-bill .ro-credit-card .ro-body .ro-info > p {
  display:inline-block;
  margin-bottom:20px;
}
.ro-checkout-information-2 .ro-bill .ro-credit-card .ro-body .ro-info > p:before, .ro-checkout-information-2 .ro-bill .ro-credit-card .ro-body .ro-info > p:after {
  content:" ";
  display:table;
}
.ro-checkout-information-2 .ro-bill .ro-credit-card .ro-body .ro-info > p:after { clear:both; }
.ro-checkout-information-2 .ro-bill .ro-credit-card .ro-body .ro-info > p:nth-of-type(1) {
  width:25%;
  float:left;
}
.ro-checkout-information-2 .ro-bill .ro-credit-card .ro-body .ro-info > p:nth-of-type(1) span {
  float:right;
  text-align:right;
}
.ro-checkout-information-2 .ro-bill .ro-credit-card .ro-body .ro-info > p:nth-of-type(2) {
  width:75%;
  padding-left:120px;
}
.ro-checkout-information-2 .ro-bill .ro-credit-card .ro-body .ro-info:last-of-type > p { margin-bottom:0; }
.ro-checkout-information-2 .ro-bill .ro-paypal:before, .ro-checkout-information-2 .ro-bill .ro-paypal:after {
  content:" ";
  display:table;
}
.ro-checkout-information-2 .ro-bill .ro-paypal:after { clear:both; }
.ro-checkout-information-2 .ro-bill .ro-paypal .ro-image {
  clear:both;
  float:right;
}
.ro-checkout-information-2 .ro-button {
  margin-top:60px;
  margin-bottom:85px;
  text-align:center;
}
.ro-checkout-information-2 .ro-button a {
  -webkit-transition:all 0.4s;
  -moz-transition:all 0.4s;
  -o-transition:all 0.4s;
  transition:all 0.4s;
  border:0;
  background-color:$c-darkGrey;
  color:$c-white;
  font-size:14px;
  padding:15px 60px;
}
.ro-checkout-information-2 .ro-button a:hover { background-color:$c-orange; }
@media (min-width: 1200px) {
  .ro-checkout-information-2 .ro-divide { width:580px; }
  .ro-checkout-information-2 .ro-customer-info .ro-title span { right:40px; }
  .ro-checkout-information-2 .ro-shipping .ro-content { margin-right:80px; }
  .ro-checkout-information-2 .ro-bill { margin-right:80px; }
  .ro-checkout-information-2 .ro-button { margin-right:80px; }
}
@media (max-width: 767px) {
  .ro-checkout-information-2 .ro-bill .ro-credit-card .ro-body .ro-info > p:nth-of-type(1) { width:40%; }
  .ro-checkout-information-2 .ro-bill .ro-credit-card .ro-body .ro-info > p:nth-of-type(2) {
    width:60%;
    padding-left:0;
    text-align:right;
  }
}
@media (max-width: 479px) {
  .ro-checkout-information-2 .ro-bill .ro-credit-card .ro-head .ro-image img { width:100px; }
  .ro-checkout-information-2 .ro-bill .ro-credit-card .ro-body .ro-info > p:nth-of-type(1) { width:50%; }
  .ro-checkout-information-2 .ro-bill .ro-credit-card .ro-body .ro-info > p:nth-of-type(2) {
    width:50%;
    padding-left:0;
    text-align:right;
  }
  .ro-checkout-information-2 .ro-bill .ro-paypal .ro-image img { width:134.4px; }
}
.ro-checkout-summary {
  border:1px solid #CCCCCC;
  margin-top:85px;
  margin-bottom:85px;
  font-weight:400;
}
.ro-checkout-summary .ro-title {
  background-color:#EEEEEE;
  padding:25px 35px;
}
.ro-checkout-summary .ro-title h4 {
  text-align:center;
  margin:0px;
}
.ro-checkout-summary .ro-body { padding:0 35px; }
.ro-checkout-summary .ro-body .ro-item {
  padding:40px 0 0;
  font-size:14px;
}
.ro-checkout-summary .ro-body .ro-item:before, .ro-checkout-summary .ro-body .ro-item:after {
  content:" ";
  display:table;
}
.ro-checkout-summary .ro-body .ro-item:after { clear:both; }
.ro-checkout-summary .ro-body .ro-item:not(:last-of-type) { border-bottom:1px solid #CCCCCC; }
.ro-checkout-summary .ro-body .ro-item .ro-image {
  width:25%;
  float:left;
  padding-bottom:40px;
}
.ro-checkout-summary .ro-body .ro-item .ro-image img {
  width:100%;
  -webkit-transition:all 0.4s;
  -moz-transition:all 0.4s;
  -o-transition:all 0.4s;
  transition:all 0.4s;
  opacity:1;
}
.ro-checkout-summary .ro-body .ro-item .ro-image img:hover { opacity:0.5; }
.ro-checkout-summary .ro-body .ro-item > div, .ro-checkout-summary .ro-body .ro-item > div .ro-quantity, .ro-checkout-summary .ro-body .ro-item > div .ro-item-color, .ro-checkout-summary .ro-body .ro-item > div .ro-price { display:inline-block; }
.ro-checkout-summary .ro-body .ro-item > div .ro-quantity { border-top:none; }
.ro-checkout-summary .ro-body .ro-item > div:nth-of-type(2) {
  width:75%;
  padding-left:20px;
}
.ro-checkout-summary .ro-body .ro-item > div:nth-of-type(2) .ro-name {
  margin-top:15px;
  text-transform:capitalize;
}
.ro-checkout-summary .ro-body .ro-item > div:nth-of-type(2) .ro-name a:hover { color:$c-orange; }
.ro-checkout-summary .ro-body .ro-item > div:nth-of-type(3) {
  width:75%;
  padding-left:20px;
}
.ro-checkout-summary .ro-body .ro-item > div:nth-of-type(3) .ro-price { width:33.33%; }
.ro-checkout-summary .ro-body .ro-item > div:nth-of-type(3) .ro-price p {
  margin-bottom:0;
  color:$c-orange;
}
.ro-checkout-summary .ro-body .ro-item > div:nth-of-type(3) .product-total { float:right; }
.ro-checkout-summary .ro-footer > div {
  padding:0 35px;
  background-color:#EEEEEE;
}
.ro-checkout-summary .ro-footer > div p {
  margin:0px;
  padding:20px 0;
  color:#666666;
  font-size:12px;
  vertical-align:middle;
  line-height:25px;
}
.ro-checkout-summary .ro-footer > div p:before, .ro-checkout-summary .ro-footer > div p:after {
  content:" ";
  display:table;
}
.ro-checkout-summary .ro-footer > div p:after { clear:both; }
.ro-checkout-summary .ro-footer > div p span {
  font-size:14px;
  clear:both;
  float:right;
}
.ro-checkout-summary .ro-footer > div .ro-divide {
  width:100%;
  border-bottom:1px solid #CCCCCC;
}
.ro-checkout-summary .ro-footer > div:last-of-type { background-color:$c-white; }
.ro-checkout-summary .ro-footer > div:last-of-type p {
  padding:30px 0;
  display:block;
}
.ro-checkout-summary .ro-footer > div:last-of-type p span {
  font-size:18px;
  color:$c-orange;
}
.ro-checkout-summary .ro-footer > div.shipping > p {
  width:30%;
  float:left;
}
.ro-checkout-summary .ro-footer > div.shipping .ro-shipping-method {
  width:70%;
  float:right;
}
.ro-checkout-summary.ro-style-2 .ro-title { background-color:#F5F4F1; }
.ro-checkout-summary.ro-style-2 .ro-footer > div { background-color:#F5F4F1; }
.ro-checkout-summary.ro-style-2 .ro-footer > div:last-of-type { background-color:$c-white; }
@media (min-width: 1200px) {
  .ro-checkout-summary .ro-body form .ro-item > div:nth-of-type(2) { margin-top:15px; }
}
@media (max-width: 991px) {
  .ro-checkout-summary {
    max-width:456px;
    margin-left:auto;
    margin-right:auto;
    margin-top:20px;
  }
}
.woocommerce table.woocommerce-checkout-review-order-table {
  border:1px solid #CCCCCC;
  margin-top:85px;
  margin-bottom:85px;
  font-weight:400;
  border-radius:0;
  border-color:$c-lightGrey;
}
.woocommerce table.woocommerce-checkout-review-order-table .product-name {
  background-color:#EEEEEE;
  padding:25px 35px;
}
.woocommerce table.woocommerce-checkout-review-order-table td, .woocommerce table.woocommerce-checkout-review-order-table th { padding:20px 15px 15px; }
.woocommerce table.woocommerce-checkout-review-order-table thead th.product-name {
  font-size:16px;
  text-transform:capitalize;
  color:#232530;
}
.woocommerce table.woocommerce-checkout-review-order-table tbody .ro-name {
  color:#5d5d5d;
  text-transform:capitalize;
  padding-top:25px;
}
.woocommerce table.woocommerce-checkout-review-order-table tbody .ro-name:hover a { color:$c-orange; }
.woocommerce table.woocommerce-checkout-review-order-table tbody .ro-image a {
  position:relative;
  display:inline-block;
  max-width:100%;
}
.woocommerce table.woocommerce-checkout-review-order-table tbody .ro-image a img {
  width:auto;
  display:block;
}
.woocommerce table.woocommerce-checkout-review-order-table tbody .product-total { padding-top:35px; }
.woocommerce table.woocommerce-checkout-review-order-table .amount { font-size:15px; }
.woocommerce table.woocommerce-checkout-review-order-table .ro-price .amount { font-size:13px; }
.woocommerce table.woocommerce-checkout-review-order-table tfoot th {
  border-right:none;
  text-transform:capitalize;
}
@media (max-width: 479px) {
  .woocommerce table.woocommerce-checkout-review-order-table .ro-item [class*="col-xs"] {
    float:none;
    width:100%;
    text-align:center;
    padding-top:10px;
  }
}

.single-product .ro-product-wrapper .ro-product-information .ro-product-content {
  margin-top: 1.618em;

  p {
    margin:0 0 0 5px !important;
    font-size:15px;
  }

  a {
    color:$c-orange;
  }
}

.single-product .ro-product-wrapper .ro-product-information {

  .ro-product-price-meta {

    .add-to-cart-form {
      margin: 0 0 30px;

      .btn-add-to-cart {
        width:145px;
      }
    }

    .woocs_price_code {
      .amount {
        position: relative;
        top: 5px;
        color: $c-darkRedGrey;
        font-size: 20px;
      }
    }

    .single_add_to_cart_button {
      margin-left: 20px;
      background-color: $c-orange;
      border-color: $c-orange;

      &.altBuy--btn {
        margin-left:0;
        font-size: 14px;
        cursor:pointer;
      }
    }

    .altBuy--title {
      margin-bottom:6px !important;
    }
    .altBuy--info {
      margin-top: 10px;
      font-size: 16px;
    }

  }

  .add-to-cart-form p {
    margin-bottom:6px !important;

    &.p-info {
      margin-top: 10px;
      font-size: 16px;

      a {
        color: $c-orange;
      }
    }
  }

}

.woocommerce div.product {

  .woocommerce-tabs ul.tabs {

    li {
      cursor: pointer;

      &.active {
        background: $c-red !important;
        border-color: $c-red !important;

        a {
          color: $c-white !important;
          opacity: 1 !important;

          &:hover {
            color: $c-white !important;
            opacity: 1 !important;
          }
        }
      }

      a {
        display: block;
        cursor: pointer !important;
      }

    }
  }

  .woocommerce-tabs ul.alpha-tabs {

    li {
      /*border-right:0 !important;*/

      a {
        font-size:12px;
        padding:10px 18px;
      }
    }
  }
}

.song-list {
  .tb-product-items {
    font-size:13px !important;

    a {
      color:$c-orange;
    }
  }
  .tb-product-items + li {
    margin-top:.2em;
  }
}

.div-table {
  display: table;
  width: 100%;
  background-color: $c-white;
  border-spacing: 5px; /* cellspacing:poor IE support for  this */

  .div-table-row {
    display: table-row;
    width: auto;
    clear: both;
    font-size:13px
  }
  .div-table-row:nth-child(odd) {
    background-color: $c-lightGrey;
  }
  .div-table-row-header {
    font-weight:700;
    background-color: $c-red !important;
    color: $c-white;
    border:none;
  }
  .div-table-col-title {
    font-size:15px;
  }
  .div-table-col {
    float: left; /* fix for  buggy browsers */
    display: table-column;
    width: 15%;
    padding: 3px 5px 0px;
  }
  .div-table-col-wide { width:42%; }
  .div-table-col-wider { width:60%; }
  .div-table-col-med { width:28%; }
  .div-table-col-small { width:5%; }

}

mark{ background:yellow;color:black; }

.searchtable-searchall {
  label { padding-top:8px; }
  input { width:100% !important; }
}

#songsearchtable {
  border:none !important;
  margin-bottom:20px;

  .div-table-col-wide { width:42%; }
  .div-table-col-wider { width:60%; }
  .div-table-col-med { width:28%; }
  .div-table-col-small { width:15%; }
  .div-table-col-hidden { display:none; }

  td, th {
    border:none !important;
  }

  thead {
    background-color: $c-red !important;
    color: $c-white;
    border:none;

    th {
      font-weight:700;
      background-color: $c-red !important;
      color: $c-white;
      border:none;
      font-size:13px;
      padding: 3px 5px 0;
    }
  }

  tbody td,
  tbody th {
    padding: 3px 5px 0;
  }

  tbody {
    font-size:13px;

    tr.odd td {
      background-color: $c-offwhite;
    }

    h3 {
      font-size:14px !important;
      margin-bottom:0 !important;

      a.updated-Yes:after {
        display: inline-block;
        content: ' ';
        background-image: url('../sync-alt-solid.svg');
        background-size: 14px 14px;
        height: 14px;
        width: 14px;
        position: relative;
        top:2px;
        left:5px;
      }

    }
  }
}


.update-icon{
  stroke:$c-green;
}

.sidebar-area .widget {

  h2 {
    font-weight: 700;
    font-size:18px;
    margin-top:20px;
  }

  h3.wg-title {
    font-size:15px;
  }

  .select-row {
    margin-bottom:10px !important;
    padding-bottom:15px !important;

    input {
      width:100% !important;
    }
  }

  ul.category-list {
    li {
      border-bottom:none;
      line-height:28px;
      font-size:13px;
    }

    li + li {
      margin-top:0px;
    }

    input[type="checkbox"], input[type="radio"] {
      position: relative;
      top:2px;
    }
  }

  .radio-row {
    input[type="checkbox"], input[type="radio"] {
      position: relative;
      top:1px;
    }
  }


  .filter-box-bottom {
    border-bottom:1px solid $c-lightGrey;
    padding-bottom:20px;
    margin-bottom:20px;
    height:50px;

    .filter-box-more {
      font-size:14px;
      color:$c-darkBlueGrey;
      cursor:pointer;

      i { margin-right:3px; }
    }
  }
}

.prepay-note {
  font-size:.65em !important;

  span {
    color:$c-red;
    font-style:italic;
  }

  i {
    color:$c-grey;
    margin-left:4px;
    font-size:16px;
  }
}

.prepay-in-use {
  .remove {
    display:inline-block !important;
    position: relative;
    top:2px;
  }
}

.prepay-cards-list {
  list-style:none;
  margin:0;

  li + li {
    padding-top:15px;
    border-top:1px solid #ebebeb;
  }
}

.woocommerce-checkout-review-order-table { font-size:14px; }
.woocommerce table.woocommerce-checkout-review-order-table td { padding:6px 15px; }

/*#paypal-button {
  opacity:0;
  z-index:10;
}
#new-paypal-button {
  width:180px;
  height:38px;
  color:white;
  background-color: $c-orange;
  z-index:2;
  display: inline-block;
  position: relative;

  &:hover {
    opacity: 0.7;
  }
}*/
#paypal-icons {
  width:319px;
  height:110px;
  display:block;
  margin-top:20px;
  background:transparent url('/assets/images/Paypal_logo250.png') 0 0 no-repeat;
}

.order-prepay-title {
  margin-top:20px;
  margin-bottom:05px !important;
}

.download-item {
  .button {
    width: auto !important;
  }
  margin-bottom:10px;
}


#paypal-button {
  width: 319px;
  height: 110px;
  display: block;
  /*margin-bottom: 40px;*/
  background: transparent url('/assets/Paypal_logo250.png') 0 0 no-repeat;
}
/*.paypal-rest-form {
  &:before {
    content: 'Pay with: ';
  }
}*/
.stripe-payment-intents-form {
  &:before {
    content: url('/assets/CreditCard_logo310.png');
  }
}

.woocommerce-tabs {
  h3 {
    margin-top:10px;
  }
}


.account-list {
  border-bottom:1px solid $c-lightishGrey;
  padding-bottom:20px;
  margin-bottom:20px;

  h4 {
    margin-bottom:2px;
  }
  p {
    margin:0;
    padding:0;
  }
}

.coupon__Container {
  .coupon__Form {
    margin-top:10px; margin-left:10px;
  }
  .coupon__Input {
    line-height: 21.8px !important;
    font-size:14px;
    width:60%;
  }
  .coupon__Submit {
    font-size: 11px;
    width: 30%;
    margin: 0;
    line-height: 1;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    overflow: visible;
    left: auto;
    border: 0;
    border-radius:0;
    font-weight:400;
    display: inline-block;
    background-image: none;
    box-shadow: none;
    text-shadow: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    padding: 17.5px 20px !important;
  }
  @media screen and (-webkit-min-device-pixel-ratio:0)
  and (min-resolution:.001dpcm) {
    .coupon__Submit {
      padding: 15.5px 20px !important;
    }
  }
}

#paymentForm__container {
  .payment-form-fields input {
    width:100%;
    line-height:21px !important;
  }

  input.text {
    line-height:21px !important;
    font-size:16px !important;
  }

  ::placeholder { color:#7c7c7c; font-family: 'Poppins', sans-serif !important; }
  ::-webkit-input-placeholder { color:#7c7c7c; font-family: 'Poppins', sans-serif !important; }
  :-moz-placeholder { color:$c-black; font-family: 'Poppins', sans-serif !important; }
  ::-moz-placeholder { color:$c-black; font-family: 'Poppins', sans-serif !important; }
  :-ms-input-placeholder { color:$c-black; font-family: 'Poppins', sans-serif !important; }

  .card-data  {
    margin: 0 0 20px !important;
  }
}

.StripeElement,
.StripeElement--empty {
  background-color:$c-offwhite !important;
  border:1px solid $c-lightGrey !important;
  color: #777 !important;
  font-family: 'Poppins', sans-serif !important;
  font-size:18px !important;
  padding:10px  !important;
}