.tb-error404-wrap {
  font-size:24px;
  line-height:22px;
  background-size:cover;
}
.tb-error404-wrap .overlay-background { opacity:0.5; }
.tb-error404-wrap a { font-size:18px; }
.tb-error404-wrap a.btn-transparent { margin-left:27px; }
.tb-error404-wrap .widget_search #searchform {
  max-width:472px;
  width:80%;
}
.tb-error404-wrap .widget_search #s {
  font-size:14px;
  color:#b0b0b0;
  line-height:35px;
  padding-left:15px;
  /* WebKit, Blink, Edge */
  /* Mozilla Firefox 4 to 18 */
  /* Mozilla Firefox 19+ */
  /* Internet Explorer 10-11 */
  /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
  height:47px;
}
.tb-error404-wrap .widget_search #s::-webkit-input-placeholder { color:#818181; }
.tb-error404-wrap .widget_search #s:-moz-placeholder {
  color:#818181;
  opacity:1;
}
.tb-error404-wrap .widget_search #s::-moz-placeholder {
  color:#818181;
  opacity:1;
}
.tb-error404-wrap .widget_search #s:-ms-input-placeholder { color:#818181; }
.tb-error404-wrap .widget_search #s:placeholder-shown { color:#818181; }
.tb-error404-wrap .widget_search #s:focus { color:$c-black; }
.tb-error404-wrap .widget_search #searchsubmit {
  line-height:27px;
  font-size:18px;
}
.tb-error404-wrap .content-fixed h3 {
  /* text color */
  color:#232530;
  font-size:36px;
  font-weight:300;
  margin-top:32px;
  margin-bottom:32px;
  line-height:36px;
}
.tb-error404-wrap .content-fixed p {
  font-size:16px;
  color:$c-darkBlueGrey;
  font-weight:300;
}
.tb-error404-wrap .content-fixed a {
  background:$c-green;
  color:$c-white;
  font-size:13px;
  text-transform:uppercase;
  font-weight:500;
  -moz-border-radius:2px;
  -webkit-border-radius:2px;
  border-radius:2px;
  padding:0px 40px;
  line-height:40px;
  display:inline-block;
  margin-top:30px;
}