#home-main-image {
  margin-bottom:20px;
}

.infoBox {
  width:100%;
  padding:40px;
  font-weight:300;

  h2 {
    font-size:30px;
    font-weight:400;
  }

  &.bkgrd-red {
    background-color:$c-red;
    color:$c-white;

    h2 {
      color:$c-white;
    }
  }

  &.bkgrd-lightBlue {
    background-color: $c-lightBlue;
  }

  &.bkgrd-none {
    background-color: transparent;
  }
}

.callout-box {
  margin-bottom:20px;

  .callout-box-wrap {
    margin-top:-20px;
    padding: 40px 20px 10px;
    border-left:1px solid $c-red;
    border-right:1px solid $c-red;
    border-bottom:1px solid $c-red;

    P {
      font-size: 16px;
      margin-bottom:4px !important;
      line-height:20px;
    }
    p.text-right {
      font-style: italic;
    }
  }

  h2 {
    margin-bottom:0px;

    span {
      font-weight:400;
      color:white;
      background-color: $c-red;
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase;
      padding: 14px 30px;
      display: inline-block;
      width: 100%;
    }
  }
}

.image-right {
  float:right;
  margin-left:5px;
  margin-bottom:5px;
}
.image-left {
  float:left;
  margin-right:5px;
  margin-bottom:5px;
}
.image-center {
  display: block;
  margin:0 auto;
}
.image-full {
  display:block;
  width:100%;
  margin:0 0 0 5px;
}

#home-slider {
  ul {
    li {
      img {
        border:1px solid $c-lightGrey;
      }
    }
  }
  p.flex-caption {
    background-color:$c-black;
    color:$c-white;
    margin-bottom:0;
    padding: 16px;
  }

  .flex-control-nav {
    bottom:-20px;
  }
}

.col-boxed {
  min-height:300px;

  .col-box-content {
    border: 1px solid $c-lightGrey;

    h3 {
      background: $c-orange;
      color: $c-white;
      padding: 5px 10px;
    }

    .col-box-body {
      padding:10px;

      img {
        max-width:100px;
        float:left;
        padding:3px;
        border:1px solid $c-lightGrey;
        margin-bottom:10px;
      }

      p {
        margin:0 0 0 15px !important;
        line-height:1.4 !important;
      }
    }
  }
}
.home .deersmart-title-default {

  h3.deersmart-title {
    font-size:24px;
  }
}

.friend-box {
  img {
    text-align: center;
    margin: 0 auto;
  }
}
