.main-content {
  a {
    color:$c-orange;
  }
}

.table-col-title {
  width:80%;
}
.table-col-page {
  width:18%;
}
.table-col-half {
  width:50%;
}
.table-col-quarter {
  width:25%;
}
.center-img {
  margin:0 auto;
}

.container {
  ul, ul li,
  ol, ol li,
  p {
    font-size:16px;
  }
}

.datatable,
.tablesort {

  h4 {
    margin-bottom:5px;
  }

  th {
    cursor: pointer;

    &.sorting_desc:after,
    &.sorting_asc:after,
    &.sorted:after {
      margin-left: 10px;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &.sorting_asc:after,
    &.ascending:after {
      content: "\f0d8";
    }

    &.sorting_desc:after,
    &.descending:after {
      content: "\f0d7";
    }

  }
}

.dataTables_wrapper {
  .dt-buttons {
    position: absolute;
    bottom:-30px;

    button {
      border:none;
      @include fontsize(13);
      color:$c-darkBlue;

      &:hover {
        color:$c-red;
      }
    }
  }
}

.woocommerce-Tabs-panel--songs,
.woocommerce-Tabs-panel--orders
{
  padding-bottom:40px !important;
}

.white-popup {
  position: relative;
  background: $c-white;
  padding: 40px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
  font-family:Poppins;

  .btn-orange {
    background-color:$c-orange;
    color:$c-white;
  }

  a {
    color:$c-orange;
  }

  .more-actions {
    margin-top:1em;
    padding-top:1em;
    border-top:1px solid $c-lightGrey;
  }
}
.wide-popup {
  max-width:800px;
}

.card-container {
  margin:40px;

  .card {
    border-radius: 20px;
    background-color: $c-red;
    margin: 20px auto;
    padding: 40px;
    color: white;

    h2 {
      font-size: 28px;
    }

  }
}

ul.no-list {
  list-style:none;
  margin:0;

  li {
    margin:0;
  }
}

.accordion {
  h4 {
    font-weight:700;
    cursor:pointer;
    /*color:$c-orange;*/
    color: $c-darkBlueGrey;
    font-size:17px;

    i {
      margin-right:8px;
      font-size: 14px;
      top: -1px;
      position: relative;
    }
  }

  dd {
    margin-left:24px;
  }
}

.faq-page {
  @media (min-width: 576px) {
    .left-col {
      padding-right: 1.5rem;
    }
    .right-col {
      padding-left: 1.5rem;
    }
  }
}

.sidebar-area {
  .select-title {
    margin-bottom:10px !important;
  }
  .select-row {
    padding-bottom:20px;
    margin-bottom:30px;
  }
  select {
    max-width:100%;
    width:100% !important;
    height:auto;
  }
  label {
    font-weight:400 !important;
    font-size:.85em;
  }
}

.new-star {
  display: inline-block;
}

.lower { text-transform: lowercase; }

.meta-note {
  font-size:13px !important;

  span {
    margin-right:10px;
    top: 8px;
    height: 50px;
    display: block;
    float: left;
    background-repeat: no-repeat;
  }
}

.collection-information .ro-product-content ul {list-style:disc !important; margin:20px 30px !important;}
.collection-information {padding-left:20px !important;}
.single-product .ro-product-wrapper .ro-product-information.collection-information .ro-product-content p {margin:20px 0 !important;}