.jws_theme_footer .main-logo { width:auto; }
.jws_theme_footer .wg-title {
  font-weight:700;
  font-size:16px;
  line-height:28px;
  margin-bottom:0;
  text-transform:capitalize;
  color:$c-lightishGrey;
}
.jws_theme_footer ul {
  margin:0;
  padding:0;
  list-style:none;
}
.jws_theme_footer ul li a {
  font-size:14px;
  font-weight:300;
  line-height:30px;
  color:$c-lightBlueGrey;
  /*text-transform:capitalize;*/
  -webkit-transition:all 0.3s;
  -moz-transition:all 0.3s;
  -o-transition:all 0.3s;
  transition:all 0.3s;
}
.jws_theme_footer ul li a:hover { color:$c-orange; }
.jws_theme_footer ul.center_one { margin-top:14px; }
.jws_theme_footer ul.center_one li { margin-bottom:12px; }
.jws_theme_footer ul.center_one li {
  @extend %sansSerif;
  font-weight:300;
  font-size:14px;
  line-height:18px;
  color:$c-lightBlueGrey;
}
.jws_theme_footer ul.center_one li span {
  color:$c-white;
}
.jws_theme_footer ul.center_one li span.footer-indent {
  padding-left:64px;
}
.jws_theme_footer ul.center_one li i {
  color:$c-white;
  font-size:15px;
}
.social-circle {
  border:1px solid $c-white;
  border-radius:50%;
  padding:5px 10px;
  text-align:center;
  margin-left:8px;
}
.social-circle:hover { border:1px solid $c-white; }
.jws_theme_footer ul.center_one li i:hover { color:$c-orange; }

.jws_theme_footer ul.center_one li strong {
  font-size:20px;
  color:$c-white;
  font-weight:500;
}
.jws_theme_footer ul.center_one li a:hover span {
  color:$c-orange;
}

.jws_theme_footer ul.share_social { margin-top:12px; }
.jws_theme_footer ul.share_social li {
  display:inline-block;
  vertical-align:middle;
  padding-right:25px;
}
.jws_theme_footer ul.share_social li a {
  display:block;
  -webkit-border-radius:4px;
  -moz-border-radius:4px;
  border-radius:4px;
  font-size:14px;
  color:$c-lightBlueGrey;
  text-align:center;
  -webkit-transition:all 0.3s;
  -moz-transition:all 0.3s;
  -o-transition:all 0.3s;
  transition:all 0.3s;
}
.jws_theme_footer ul.share_social li a:hover { color:$c-orange; }
.jws_theme_footer .title_newletter h3 {
  margin-bottom:9px;
  font-size:24px;
  color:$c-white;
  text-transform:uppercase;
}
.jws_theme_footer .title_newletter p {
  font-size:14px;
  color:$c-white;
  margin-bottom:0 !important;
}
.jws_theme_footer .jws_theme_before_footer_1 ul.list-social li a {
  border-radius:0px;
  background:none;
  margin-right:30px;
  width:auto;
  height:auto;
}
.jws_theme_footer .jws_theme_before_footer_1 ul.list-social li a i {
  color:$c-lightBlueGrey;
  -webkit-transition:all 0.3s;
  -moz-transition:all 0.3s;
  -o-transition:all 0.3s;
  transition:all 0.3s;
}
.jws_theme_footer .jws_theme_before_footer_1 ul.list-social li a:hover i { color:$c-orange; }


.jws_theme_footer.bottom-section .widget_text:last-child { margin-top:10px; }
.jws_theme_footer .widget_newsletterwidget .newsletter-desc p {
  font-weight:400;
  text-align:left;
  font-size:14px;
  line-height:26px;
  color:$c-lightBlueGrey;
}
.jws_theme_footer .newsletter, .jws_theme_footer .widget_newsletterwidget {
  color:$c-lightBlueGrey;
  margin-top:3px;
}
.jws_theme_footer .newsletter .tnp-field-button, .jws_theme_footer .widget_newsletterwidget .tnp-field-button {
/*  position:absolute;
  right:0;
  top:0;
  min-width:120px;*/
}
.jws_theme_footer .newsletter .tnp-field-button, .jws_theme_footer .widget_newsletterwidget .row .col-xs-4,
.jws_theme_footer .newsletter .tnp-field-button, .jws_theme_footer .widget_newsletterwidget .row .col-xs-8{
  padding:0;
}
.jws_theme_footer .newsletter .tnp-field-button input, .jws_theme_footer .widget_newsletterwidget .tnp-field-button input { border:none; }
.jws_theme_footer .newsletter form, .jws_theme_footer .widget_newsletterwidget form {
  margin-top:30px;
  position:relative;
  overflow:hidden;
  max-width:475px;
  -webkit-border-radius:1px;
  -moz-border-radius:1px;
  border-radius:1px;
}
.jws_theme_footer .newsletter form p, .jws_theme_footer .widget_newsletterwidget form p {
  margin:0;
  margin-bottom:0 !important;
  display:inline-block;
  vertical-align:middle;
}
.jws_theme_footer .newsletter form p.get_email, .jws_theme_footer .widget_newsletterwidget form p.get_email {
  width:calc(100% - 123px);
  width:-webkit-calc(100% - 123px);
  border:1px solid #393b45;
  border-right:none;
}
.jws_theme_footer .newsletter form p.get_submit, .jws_theme_footer .widget_newsletterwidget form p.get_submit {
  width:123px;
  border:1px solid $c-orange;
  border-left:none;
}
.jws_theme_footer .newsletter form input, .jws_theme_footer .widget_newsletterwidget form input {
  margin:0;
  border:0;
  padding:0 20px;
  font-weight:500;
  font-size:12px;
  height:40px;
  line-height:41px;
  border-color:transparent;
  @extend %sansSerif;
  background-color:$c-farFooter;
  box-shadow:none;
  color:$c-white;
  border:1px solid #393b45;
}
.woocommerce .newsletter-form input.tnp-email {
  width:100% !important;
}
.jws_theme_footer .newsletter form input:focus, .jws_theme_footer .widget_newsletterwidget form input:focus { color:$c-white; }
.jws_theme_footer .newsletter form input[type="submit"], .jws_theme_footer .widget_newsletterwidget form input[type="submit"] {
  font-size:12px;
  color:$c-white;
  @extend %sansSerif;
  font-weight:500;
  text-transform:uppercase;
  line-height:35px;
  background:$c-orange;
}
.jws_theme_footer .newsletter form .tb-newsletter-checkbox, .jws_theme_footer .widget_newsletterwidget form .tb-newsletter-checkbox { display:none; }
.jws_theme_footer.tb-footer-v1 .footer-center { background-color:$c-darkBlue; }
.jws_theme_footer.tb-footer-v1 .footer-center .newsletter-desc .wg-title { display:none; }
.jws_theme_footer.tb-footer-v1 .footer-center .jws_theme_before_footer_1 img.main-logo {
  margin-top:5px;
  margin-bottom:5px;
}
.jws_theme_footer.tb-footer-v1 .footer-center .jws_theme_before_footer_1 ul.center_one { margin-bottom:20px; }
.jws_theme_footer.tb-footer-v1 .footer-center h3.wg-title { margin-bottom:8px; }
.jws_theme_footer.tb-footer-v1 .footer-center .jws_theme_before_footer_4 .newsletter-desc p { margin-bottom:32px !important; }
.jws_theme_footer.tb-footer-v2 .footer-top, .jws_theme_footer.tb-footer-v3 .footer-top {
  padding-top:45px;
  padding-bottom:42px;
  padding-left:45px;
  padding-right:45px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .jws_theme_footer ul.share_social li { margin-bottom:5px; }
}
@media (max-width: 767px) {
  .jws_theme_footer .footer-top { text-align:Center; }
  .jws_theme_footer .footer-top .jws_theme_footer_top_once { margin-bottom:20px; }
  .jws_theme_footer .newsletter form { margin:0 auto; }
  .footer-center .jws_theme_footer_center_once, .footer-center .jws_theme_footer_center_two, .footer-center .jws_theme_footer_center_three, .footer-center .jws_theme_footer_center_four { text-align:Center; }
  .footer-bottom .jws_theme_footer_bottom_once .text_bottom_left, .footer-bottom .jws_theme_footer_bottom_two .text_bottom_left {
    text-align:center;
    margin-bottom:15px !important;
  }
  .footer-bottom .jws_theme_footer_bottom_once ul, .footer-bottom .jws_theme_footer_bottom_two ul { text-align:center; }
  .footer-bottom .jws_theme_footer_bottom_once ul li, .footer-bottom .jws_theme_footer_bottom_two ul li { padding:0 5px; }
}

.jws_theme_footer {
  margin-top:0;
  margin-right:0;
  margin-bottom:0;
  margin-left:0;
}
.jws_theme_footer {
  padding-top:0;
  padding-right:0;
  padding-bottom:0;
  padding-left:0;
}
.jws_theme_footer .footer-top {
  background-color:#eceff6;
}
.jws_theme_footer .footer-top {
  margin-top:0;
  margin-right:0;
  margin-bottom:0;
  margin-left:0;
}
.jws_theme_footer .footer-top {
  padding-top:35px;
  padding-right:0;
  padding-bottom:28px;
  padding-left:0;
}
.jws_theme_footer .footer-center {
  background-color:$c-white;
}
.jws_theme_footer .footer-center {
  margin-top:0;
  margin-right:0;
  margin-bottom:0;
  margin-left:0;
}
.jws_theme_footer .footer-center {
  padding-top:80px;
  padding-right:0;
  padding-bottom:65px;
  padding-left:0;
}
.jws_theme_footer .footer-bottom {
  background-color:$c-farFooter;
}
.jws_theme_footer .footer-bottom {
  margin-top:0;
  margin-right:0;
  margin-bottom:0;
  margin-left:0;
}
.jws_theme_footer .footer-bottom {
  padding-top:0;
  padding-right:0;
  padding-bottom:0;
  padding-left:0;
}

.jws_theme_footer li + li {
  margin:0;
}

.jws_theme_footer .footer-bottom p {
  @extend %sansSerif;
  font-weight:300;
  font-size:12px;
  line-height:50px;
  margin-bottom:0 !important;
  color:$c-darkBlueGrey;
}
.jws_theme_footer .footer-bottom p a { color:$c-orange; }
.jws_theme_footer .footer-bottom p a:hover { color:$c-white; }

.jws_theme_footer .jws_theme_footer_bottom_right ul {
  text-align:right;
  line-height:50px;
}
.jws_theme_footer .jws_theme_footer_bottom_right ul li {
  display:inline-block;
  vertical-align:middle;
  padding-left:10px;
}

@media (max-width: 767px) {
  .jws_theme_footer .footer-bottom p,
  .jws_theme_footer_bottom_right.text-right {
    text-align:center !important;
  }
}