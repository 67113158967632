/*.comment-form p.form-submit {
  margin:0;
  display:inline-block;
  vertical-align:middle;
  margin-top:30px;
  overflow:hidden;
  position:relative;
}*/

.btn {
  text-transform:uppercase;
  @extend %sansSerif;
  font-weight:500;
  font-size:13px;
  letter-spacing:1px;
  border:1px solid $c-orange;
  background:$c-orange;
  line-height:28.8px;
  padding:10px 40px;
  color:$c-white;
}

.btn-grey {
  border:1px solid $c-darkGrey !important;;
  background:$c-darkGrey !important;;
  color:$c-white !important;
}
.btn-orange {
  border:1px solid $c-orange !important;
  background:$c-orange !important;
  color:white !important;
  width:auto;

  &:hover {
    color:white !important;
  }
}
.btn-darkbluegrey {
  border:1px solid $c-darkBlueGrey !important;;
  background:$c-darkBlueGrey !important;;
}
.btn-mini {
  padding:.1rem 0;
}

button {
  cursor: pointer;
}

.disabled {
  background:$c-lightGrey !important;
  color:$c-darkGrey !important;
  cursor:default;
  opacity: .4 !important;
  border:1px solid $c-lightGrey !important;

  &:hover {
    opacity: .4 !important;
    cursor:default;
    color:$c-darkGrey !important;
  }

}

.thinking {
  padding: 0px 60px !important;
}
.prepay-thinking {
  width:176px;
  height:48px;
}

.woocommerce .btn-mini {
  font-size:70% !important;

  @media screen and (min-width:1200px) {
    width:30%;
  }
}
.woocommerce .button {
  padding: 14px 20px !important;
}

.woocommerce .datatable .button {
  padding: 8px 12px !important;
}

.scrollup-btn {
  display: none;
  cursor:pointer;
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 55px;
  right: 20px;
  background-color: $c-farFooter;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.2s ease;
  border-radius: 50%;
  text-align: center;
  line-height: 3em;
  z-index: 998;
  /*transition:all .3s;*/
  transition:all 0.7s !important;

  i {
    position: absolute;
    font-size:30px;
    color:$c-white;
    z-index: 999;
    left: 15px;
    top: 12px;
  }

  &:hover {
    opacity: 0.9;
  }
}

.no-click {
  a {
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }
}

.woocommerce {
  label {
    font-weight: 700;
    line-height:2;
    display:block;
  }
  input, select, textarea {
    width:auto;
  }
}

@media (max-width: 767px) {
  .woocommerce .btn-mini { width:auto; }
}